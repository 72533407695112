import React from "react";
import {globalToLocalFieldGrid} from "../../utilitis/helper";
import {useTranslation} from "react-i18next";
import DOMPurify from 'dompurify';

const CheckBox = ({fieldname = "", description = "", fieldid = "", fieldvalue = '', required = '0', editable = true, fieldcss = {}, handler = () => {}, register = () => {}, errors = () => {}}) =>{

    const [labelCol, fieldCol, labelColTablet, fieldColTablet] = globalToLocalFieldGrid(fieldcss),
        {t} = useTranslation(),
        displayname = DOMPurify.sanitize(description && description !== '' ? description : fieldname);

    return(
            <div className="row">
                <div className={`col-${labelCol} col-md-${labelColTablet} text-left`}>
                    <span className="ps-3">{displayname}{required == 1 ? ' *' : ''}</span>
                </div>
                <div className={`col-${fieldCol} col-md-${fieldColTablet}`}>
                    <div className={`yesnoswitch${errors && errors[fieldid] ? " is-invalid" : ""}`}  onClick={handler}>
                        <input type="checkbox"  className="yesnoswitch-checkbox" id={fieldid} data-guid={fieldid} {...register(fieldid, { required: required == 1})} defaultChecked={fieldvalue} disabled={(editable)? "" : "disabled"}/>
                        <label className="yesnoswitch-label" htmlFor={fieldid}>
                            <span className="yesnoswitch-inner"></span>
                            <span className="yesnoswitch-switch"></span>
                        </label>
                    </div>
                    <div className="invalid-feedback">{t("ui:generic.checkbox.invalid_message", {name: displayname})}</div>
                </div>
            </div>
        );

    // const checboxCol = ({itemindex,fieldname,fieldid, fieldcss, handler}) => {
    //     return(
    //         <div className="holder checkbox">
    //             <div className={"col-"+fieldcss.label}>
    //                 <span>{fieldname}</span>
    //             </div>
    //             <div className={"col-"+fieldcss.field}>
    //                 <div className={"yesnoswitch"}  onClick={handler}>
    //                     <input type="checkbox"  name={fieldname} className="yesnoswitch-checkbox" id={fieldname+"-"+itemindex} data-guid={fieldid} onChange={handler}/>
    //                     <label className="yesnoswitch-label" htmlFor={fieldname+"-"+itemindex}>
    //                         <span className="yesnoswitch-inner"></span>
    //                         <span className="yesnoswitch-switch"></span>
    //                     </label>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
    //
    // const checkboxColReq = ({itemindex,fieldname,fieldid, fieldcss, handler}) =>{
    //     return(
    //         <div className="holder checkbox">
    //             <div className={"col-"+fieldcss.label}>
    //                 <span>{fieldname} *</span>
    //             </div>
    //             <div className={"col-"+fieldcss.field}>
    //                 <div className={"yesnoswitch"}  onClick={handler}>
    //                     <input type="checkbox"  name={fieldname} className="yesnoswitch-checkbox" id={fieldname+"-"+itemindex} data-guid={fieldid} onChange={handler}/>
    //                     <label className="yesnoswitch-label" htmlFor={fieldname+"-"+itemindex}>
    //                         <span className="yesnoswitch-inner"></span>
    //                         <span className="yesnoswitch-switch"></span>
    //                     </label>
    //                 </div>
    //                 <div className="error-message">
    //                     {fieldname} not checked!
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
    //
    // const checkboxRow = ({itemindex,fieldname,fieldid, fieldcss, handler}) =>{
    //     return(
    //         <div className="holder">
    //             <div className={"label col-"+fieldcss.label}>
    //                 <span>{fieldname}</span>
    //             </div>
    //             <div className={"field col-"+fieldcss.field}>
    //                 <div className={"yesnoswitch"}  onClick={handler}>
    //                     <input type="checkbox"  name={fieldname} className="yesnoswitch-checkbox" id={fieldname+"-"+itemindex} data-guid={fieldid} onChange={handler}/>
    //                     <label className="yesnoswitch-label" htmlFor={fieldname+"-"+itemindex}>
    //                         <span className="yesnoswitch-inner"></span>
    //                         <span className="yesnoswitch-switch"></span>
    //                     </label>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };
    //
    // const checkboxRowReq = ({itemindex,fieldname,fieldid, fieldcss, handler}) =>{
    //     return(
    //         <div className="holder">
    //             <div className={"label col-"+fieldcss.label}>
    //                 <span>{fieldname} *</span>
    //             </div>
    //             <div className={"field col-"+fieldcss.field}>
    //                 <div className={"yesnoswitch"}  onClick={handler}>
    //                     <input type="checkbox"  name={fieldname} className="yesnoswitch-checkbox" id={fieldname+"-"+itemindex} data-guid={fieldid} onChange={handler}/>
    //                     <label className="yesnoswitch-label" htmlFor={fieldname+"-"+itemindex}>
    //                         <span className="yesnoswitch-inner"></span>
    //                         <span className="yesnoswitch-switch"></span>
    //                     </label>
    //                 </div>
    //                 <div className="error-message">
    //                     {fieldname} not checked!
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
    //
    // if(fieldcss.label >= 12 || fieldcss.field >= 12){
    //     if(!itemrequired){
    //         return checboxCol({itemindex,fieldname,fieldid, fieldcss, handler});
    //     }else{
    //         return checkboxColReq({itemindex,fieldname,fieldid, fieldcss, handler})
    //     }
    // }else{
    //     if(!itemrequired){
    //         return checkboxRow({itemindex,fieldname,fieldid, fieldcss, handler});
    //     }else{
    //         return checkboxRowReq({itemindex,fieldname,fieldid, fieldcss, handler});
    //     }
    // }

}

export default CheckBox;
