import {useSessionService} from "../services/SessionService";

const UseLogoutWhenUnAuth = () => {

    const sessionService = useSessionService()

    const handleUnAuth = () => {
        sessionService.clearSession()
    }

    return {handleUnAuth}
}

export default UseLogoutWhenUnAuth;