const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
const moment = require('moment');
const pdfjsLib = require('pdfjs-dist/build/pdf.js');

const UsePdfRender = () => {

    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const drawImage = (ctx, img, x, y, w, h) => {
        return new Promise(resolve => {
            const image = new Image();
            image.onload = function () {
                const ratio = (image.height > 0 && image.width > 0) ? Math.min(w / image.width, h / image.height) : 1;
                ctx.drawImage(image, x, y,image.width * ratio, image.height * ratio);
                resolve();
            };
            image.src = img;
        });
    };

    const drawExtraElement = async (extra, renderCoordinate, scale, ctx) => {

        if (extra.field_value === "" || renderCoordinate === null) return;

        let dataRotationRadians, image, dateFormat, font, fontSize;
        const dataOverlays = Array.isArray(renderCoordinate) ? renderCoordinate : [renderCoordinate];

        for (const dataOverlay of dataOverlays) {

            dataRotationRadians = (dataOverlay.rotate_degree ?? 0) / 180;
            fontSize = Math.ceil((dataOverlay.font_size ?? 12) * scale).toString();
            font = dataOverlays.font ?? 'Arial'
            ctx.font = `${fontSize}px ${font}`;
            ctx.rotate(dataRotationRadians);

            // render timestamp type according to the date format given in mapping coordindate,
            // a default format (DD/MM/YYYY hh:mm:ss) will be applied to render if no format found
            if (extra.field_type === 'signdate') {
                dateFormat = dataOverlay.format ?? 'DD/MM/YYYY hh:mm:ss';
                ctx.fillText(moment.unix(extra.field_value).format(dateFormat), dataOverlay.x * scale, dataOverlay.y * scale);
            } else if (extra.field_type === 'textfield') {
                ctx.fillText(extra.field_value, dataOverlay.x * scale, dataOverlay.y * scale);
            } else if (extra.field_type === 'signature' || extra.field_type === 'confirm_signature') {
                await drawImage(ctx, extra.field_value, dataOverlay.x * scale, dataOverlay.y * scale, dataOverlay.width * scale, dataOverlay.height * scale);
            }

            ctx.rotate(-dataRotationRadians);
        }
    };

    const drawPage = async (pageNumber, totalPageNumber, page, jobExtras, container) => {
        let canvas, context, renderContext;
        const scale = 2,//(page.view[2] != 0) ? container.offsetWidth / page.view[2] : 2,
            viewport = page.getViewport({scale: scale}),
            canvasID = `pdf-canvas-page-${pageNumber}`,
            elementresult = document.querySelector("canvas#" + canvasID);

        if (! elementresult) {

            const div = document.createElement("div");
            div.setAttribute("id", `pdf-container-page-${pageNumber}`);
            div.setAttribute("style", "position: relative; width:100%;");
            container.appendChild(div);
            canvas = document.createElement("canvas");
            canvas.setAttribute("id", canvasID);
            canvas.style.display = "none";
            //canvas.setAttribute("style", "width:100%;");
            div.appendChild(canvas);
        } else {
            canvas = elementresult;
        }

        context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        if (canvas.width > window.innerWidth * 0.95) {
            container.parentNode.parentNode.scroll((canvas.width - window.innerWidth) / 2, 0);
        }
        context.clearRect(0, 0, canvas.width, canvas.height);

        renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        await page.render(renderContext).promise;

        context = canvas.getContext("2d");

        for(const extra of jobExtras) {
            if (extra.field_mapping_coord) {

                const mappingIdx = (pageNumber === totalPageNumber) ? 2 : ((pageNumber === 1) ? 0 : 1),
                    renderCoordinate = Array.isArray(extra.field_mapping_coord) ? (extra.field_mapping_coord[mappingIdx] ?? extra.field_mapping_coord[0]) : extra.field_mapping_coord;

                await drawExtraElement(extra, renderCoordinate, scale, context);
            }
        }

        let finalPdfImage = canvas.toDataURL();
        if (finalPdfImage !== null && finalPdfImage !== "") {
            finalPdfImage.replace(/data:\,/g, '');
        }

        return [finalPdfImage, canvas.width, canvas.height, scale];
    }

    const convertPDFToImages = async ( data, jobExtras, container) => {

        let pdfImages = [];

        const pdf = await pdfjsLib.getDocument({
            data: atob(data)
        }).promise;

        for(let currentPageNumber = 1, totalPageNumber = pdf.numPages; currentPageNumber < totalPageNumber + 1; currentPageNumber++) {

            const page = await pdf.getPage(currentPageNumber);

            pdfImages.push(await drawPage(currentPageNumber, totalPageNumber, page, jobExtras, container));

        }

        pdf.cleanup();
        pdf.destroy();
        return pdfImages;
    };

    return {convertPDFToImages};
}

export default UsePdfRender;
