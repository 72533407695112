import React, {useState} from "react";

import {library } from '@fortawesome/fontawesome-svg-core';
import {fas,faSuitcase,faLayerGroup}  from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isTablet,isMobileOnly, isBrowser} from "react-device-detect";
import {useSelector} from "react-redux";
import {selectDeliveryType} from "../../store";
import {DELIVERY_TYPE_GROUP} from "../../constains/types";
import {Col,Row} from "react-bootstrap";
library.add(fas,faSuitcase,faLayerGroup);

const JobNoChild = ({noOfJobs , handler, disabled}) => {
    const deliveryType = useSelector(selectDeliveryType);

    return(
        <Col xs={12} className="deliveries-title">
            <Row><h3 className="text-white m-0 p-2">My Deliveries</h3></Row>
            <Row className="justify-content-center">
                {!disabled ? <Col xs={4} md={2}>
                <button className={`btn-sort ${deliveryType === DELIVERY_TYPE_GROUP ? "active": ""} ${isTablet ? "table" : (isMobileOnly ? "mobile" : "browser" )}`}  onClick={handler} disabled={disabled}>
                    <FontAwesomeIcon icon={["fas", "object-group"]}/>
                    <span>Group</span>
                </button>
                </Col> : <></>}
                <Col xs={4} md={2}>
                    <div className="job-count">
                        <span>{noOfJobs}</span>
                        <FontAwesomeIcon icon={["fas", "suitcase"]}/>
                    </div>
                </Col>
            </Row>
        </Col>
    );

}

export default JobNoChild;
