import { useState, useEffect } from "react";

export function useUserMedia(requestedMedia) {
    const [mediaStream, setMediaStream] = useState(null);

    useEffect(() => {
        async function enableVideoStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(
                    requestedMedia
                );
                setMediaStream(stream);
            } catch (err) {
                // Handle the error
                console.log(err);
            }
        }

        if (!mediaStream) {
            enableVideoStream();
        }
    }, [mediaStream]);

    return mediaStream;
}
