import { combineReducers } from 'redux';
import {connectRouter} from 'connected-react-router';
import {appStateReducer} from "./reducers/appStateReducer";
import {deliveryStateReducer} from "./reducers/deliveryStateReducer";
import {runtimeStateReducer} from "./reducers/runtimeStateReducer";


export default (history) => combineReducers({
    router: connectRouter(history),
    appStateReducer: appStateReducer,
    deliveryStateReducer: deliveryStateReducer,
    runtime: runtimeStateReducer
});
