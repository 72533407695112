
import React, {createRef, useEffect, useRef, useState} from "react";
import {useMSOGForm} from "../../services/MSOGFormContext";
import CheckBox from "../inputs/CheckBox";
import {useForm} from "react-hook-form";
import UseFormVehicle from "../../components/UseFormVehicle";
import NumberInput from "../inputs/InputField";
import InputField from "../inputs/InputField";
import DropDown from "../inputs/DropDown";
import TextArea from "../inputs/TextArea";
import {PhotoInput} from "../inputs/PhotoInput";
import {useDispatch, useSelector} from "react-redux";
import {selectAppSettings} from "../../store";
import TakePicBtn from "../inputs/TakePicBtn";
import {useTranslation} from "react-i18next";
import LoadingCircle from "../../components/LoadingCircle";
import {generateValidateParams, scrollToElement} from "../../utilitis/helper";
import {useSessionService} from "../../services/SessionService";
import {push} from "connected-react-router";
import {Form, Row, Col} from "react-bootstrap";
import InputHeader from "../inputs/InputHeader";

const PrecheckForm = ({guid, handleSafeSubmit, handleUnsafeSubmit}) => {

    const dispatch = useDispatch();
    const {formValues, _} = useMSOGForm();
    const {register, formState: {errors}, handleSubmit, control, setValue} = useForm();
    const {handleChange} = UseFormVehicle();
    const settingResponse = useSelector(selectAppSettings);
    const photoInputRefs = useRef([]);
    const {t,i18n} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sessionService = useSessionService();


    const startPhotoCaptureHandler = (e) => {
        const key = e.currentTarget.getAttribute("data-guid");
        photoInputRefs.current[key].current.startCaptureHandler(e);
    }

    const getInputPhotoCount = (key) => {
        return (photoInputRefs.current[key] && photoInputRefs.current[key].current) ? photoInputRefs.current[key].current.getPhotoCount() : 0;
    }

    async function deliverSubmit (data) {
        await sessionService.saveDriverLog(guid, 1, formValues);
        handleSafeSubmit();
    }

    const vehicleUnsafeSubmit = e => {
        e.preventDefault();
        sessionService.saveDriverLog(guid, 2, formValues);
        handleUnsafeSubmit();
    }

    const onValidationFailed = (e)=>{
        for(const key in e) {
            scrollToElement(key);
            break;
        }
    }

    const validator = {
        validator: (v, f, t = this) => {
            return true;
        }   
    }

    const renderVehicleList = () => {
        return formValues.map( value => {
            const renderElement = () => {
                switch (value.type) {
                    case "header":
                        return(
                            <InputHeader fieldname={value.name} 
                                         description={value.description}
                                         fieldid={value.name}
                                         fieldcss={value.css_field}/>
                        );
                    case "checkbox":
                        return(
                            <CheckBox fieldname={value.name}
                                      description={value.description}
                                      fieldid={value.name}
                                      required={value.required}
                                      fieldcss={value.css_field}
                                      handler={handleChange}
                                      register={register}
                                      fieldvalue={value.value}
                                      errors={errors}/>
                        );
                    case "dropdown":
                            return(
                                <DropDown fieldname={value.name}
                                                description={value.description}
                                                fieldid={value.name}
                                                fieldvalue={value.value}
                                                fieldlogic={value.logic}
                                                required={generateValidateParams(value.name, value.required)}
                                                fieldcss={value.field_css}
                                                handler={handleChange}
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                filter=""
                                                validator={validator}
                                                />
                            );
                    case "number":
                    case "text":
                    case "time":
                        return (
                            <InputField type={value.type}
                                        fieldname={value.name}
                                        description={value.description}
                                        fieldid={value.name}
                                        required={value.required}
                                        fieldvalue={value.value}
                                        fieldcss={value.css_field}
                                        handler={handleChange}
                                        register={register}
                                        errors={errors}/>
                        );
                    case "textarea":
                        return (
                          <TextArea required={value.required}
                                    fieldname={value.name}
                                    description={value.description}
                                    fieldid={value.name}
                                    fieldvalue={value.value}
                                    fieldcss={value.css_field}
                                    handler={handleChange}
                                    register={register}
                                    errors={errors}/>
                        );
                    case "photo":
                        photoInputRefs.current[value.name] = photoInputRefs.current[value.name] ?? createRef();
                        return (
                            <PhotoInput itemindex={value.name}
                                        fieldname={value.name}
                                        description={value.description}
                                        fieldid={value.name}
                                        required={value.required}
                                        fieldvalue={value.value ?? []}
                                        imageWidth={settingResponse.photo_resolution.width}
                                        imageHeight={settingResponse.photo_resolution.height}
                                        handler={handleChange}
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        ref={photoInputRefs.current[value.name]}>
                                <TakePicBtn objImages={getInputPhotoCount} imageLimitStorage={settingResponse.max_precheckphoto_number} fieldid={value.name} handler={startPhotoCaptureHandler}/>
                            </PhotoInput>
                        );
                }
            };

            const col_xs = Number(value.css_field.label) + Number(value.css_field.field),
                col_md = (value.css_field.label_tablet && value.css_field.field_tablet) ? Number(value.css_field.label_tablet) + Number(value.css_field.field_tablet) : col_xs;

            return (
                <Col key={`input-${value.name}`} md={col_md} xs={col_xs} className={`${value.type !== 'header' ? 'field' : ''} ${value.css_field.background ?? ''}`}>
                    {renderElement()}
                </Col>
            );
        });
    };

    return (
        <Form id="vehicle-check-form" onSubmit={handleSubmit(deliverSubmit,onValidationFailed)}>
            <Row className="form-content me-0 ms-0">
            {renderVehicleList()}
            </Row>
            <div className="vehicle-form-btn-holder">
                <button type="submit" className="btn-safe" disabled={isSubmitting}>
                    {isSubmitting? " ": "Vehicle Safe"}
                    <LoadingCircle isSubmitting={isSubmitting} />
                </button>
                <button type="submit" className="btn-unsafe" onClick={vehicleUnsafeSubmit} disabled={isSubmitting}>
                    {isSubmitting? " ": "Vehicle Unsafe"}
                    <LoadingCircle isSubmitting={isSubmitting} />
                </button>
            </div>
        </Form>
    );

}


export default PrecheckForm;
