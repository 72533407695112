import React, {
    createContext,
    forwardRef,
    memo,
    useContext,
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef,
    useState
} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isTablet, isMobileOnly, isIOS, isBrowser} from "react-device-detect";
import Camera from "./Camera/Camera";
import {useFieldArray} from "react-hook-form";
import {getUploadFileDataUrl} from "../../utilitis/helper";
import {PhotoInputStateContextProvider} from "../../services/PhotoInputStateContext";
import PhotoInputPreview from "./PhotoInputPreview";
import {useTranslation} from "react-i18next";



const stateReducer = (state, action) => {
    switch (action.type) {
        case 'setIsWebcamActive': {
            return  (state.isWebcamActive !== action.payload) ? {...state, isWebcamActive: action.payload} : state;
        }
        case 'setIsVideoPlaying': {
            return  (state.isVideoPlaying !== action.payload) ? {...state, isVideoPlaying: action.payload} : state;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const initialState = {
    isWebcamActive: false,
    isVideoPlaying: false
};


export const PhotoInput = forwardRef(({children, itemindex, fieldname, description, fieldid, required, fieldvalue, handler, imageWidth, imageHeight, control, register, errors}, ref) => {

    const {t} = useTranslation();
    const [state, dispatch] = useReducer(stateReducer, initialState);
    const [webcamImage,setWebcamImage] = useState(fieldvalue === undefined || fieldvalue === "" ? [] : JSON.parse(JSON.stringify(fieldvalue)));
    const [isDirty, setIsDirty] = useState(true);
    const previewRef = useRef();
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const [photoConfirmation, setPhotoConfirmation] = useState(false);
    const [photoID,setPhotoID] = useState("");
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: fieldid
    });
    const displayname = description && description !== '' ? description : fieldname;

    useEffect(() => {

        const onChange = e => {
            handler(e);
        }

        if (previewRef.current) {
            previewRef.current.addEventListener("imagechange", onChange);
            return () => {
                if (previewRef.current) {
                    previewRef.current.removeEventListener("imagechange", onChange);
                }
            }
        }
    },[]);


    useEffect(() => {
        //an empty input field was added to the form to trigger required validation,
        //remove the field if photo is attached.
        if (fields.length == 2 && fields[0].value === ""){
            remove(0);
        }

    },[fields]);


    useEffect(() => {

        if (isDirty) {
            const newImgs = webcamImage.filter(item => fields.findIndex(field => item === field.value) < 0);
            const removedFields = fields.filter(field => webcamImage.findIndex(item => item === field.value) < 0);

            for (const newImg of newImgs) {
                append({value: newImg});
            }

            for (const removedField of removedFields) {
                const index = fields.findIndex(field => field.id === removedField.id);
                remove(index);
            }
            setIsDirty(false);
        }
    },[isDirty]);


    const webcamHandler =(e)=>{
        if ( isTablet || isMobileOnly ) {
            document.getElementById(`take_photo_control-${e.currentTarget.getAttribute('data-guid')}`).click();
        } else {
            dispatch({type: 'setIsWebcamActive', payload:!state.isWebcamActive});
        }
        e.preventDefault();
    }

    const photoConfirmationPopUp = (e,imageID)=>{
        setPhotoID(imageID);
        e.preventDefault();
        return (photoConfirmation === false ? setPhotoConfirmation(true): setPhotoConfirmation(false)  );
    }

    const removeImageDB = (e) =>{

        if(photoID !== ""){
            webcamImage.splice(photoID, 1);
            setWebcamImage(webcamImage);
            previewRef.current.dispatchEvent(new CustomEvent("imagechange",{detail: webcamImage}));
            setIsDirty(true);
        }
        setPhotoID("");
        e.preventDefault();
        return (photoConfirmation === false ? setPhotoConfirmation(true): setPhotoConfirmation(false)  );
    }


    const onImageCaptured = (image) => {
        webcamImage.push(image);
        setWebcamImage(webcamImage);
        previewRef.current.dispatchEvent(new CustomEvent("imagechange",{detail: webcamImage}));
        setIsDirty(true);
    }

    const handle_photo = (event) => {
        const file = document.getElementById(`take_photo_control-${event.currentTarget.getAttribute('data-guid')}`).files[0];

        //stop processing empty file after canceling file selector
        if (file) {
            getUploadFileDataUrl(file, (image) => {
                onImageCaptured(image);
            })
        }

        //$scope.hideSignaturePad();
    };


    const RenderWebcamType = () =>{
        if(isTablet || isMobileOnly) {
            return (<input type="file" accept="image/*" capture="camera" id={`take_photo_control-${fieldid}`} data-guid={fieldid}
                           className="d-none"
                           onChange={handle_photo} />
            );
        } else {
            return (<>
                {!state.isWebcamActive ? "" : <button className="btn-close-camera" onClick={(e) => {
                    webcamHandler(e)
                }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/></button>}
                {!state.isWebcamActive ? "" : (<Camera onImageCaptured={onImageCaptured}/>)}
            </>);
        }
    };

    useImperativeHandle(ref, () => ({

        startCaptureHandler(e) {
            webcamHandler(e)
        },

        getPhotoCount() {
            return webcamImage.length;
        }

    }));

    return (
        <PhotoInputStateContextProvider state={state} dispatch={dispatch}>
            <div className="d-flex flex-column align-items-center justify-content-center w-100" ref={previewRef} data-guid={fieldid}>
                <div className={`photo-input ${errors && errors[fieldid] ? "is-invalid" : ""}`}>
                    <div className={state.isWebcamActive?"window-camera active":"window-camera"}>
                        <div className={isTablet === true && isIOS === true ? "window-camera-body iPad":"window-camera-body"} >
                            {RenderWebcamType()}
                        </div>
                    </div>
                    <h4>{displayname}{required == 1 ? ' *' : ''}</h4>
                    {children}
                </div>
                <PhotoInputPreview className={`${errors && errors[fieldid] ? "is-invalid" : ""}`} fieldid={fieldid} fields={fields} register={register} required={required} removeHandler={photoConfirmationPopUp}/>
                <div className={"invalid-feedback text-center"}>{t('ui:generic.photoinput.invalid_message')}</div>
            </div>
            <div className={photoConfirmation === true ? "photo-overlay active" : "photo-overlay"}>
                <div className={photoConfirmation === true ? "photo-return message active" : "photo-return message"}>
                    <div className="message-container">
                        <div className="message-container-item info">
                            <div>
                                <h5 className={"page-header"}>Delete Image?</h5>
                            </div>
                            <div className="d-flex flex-row">
                                <button className="btn-confirm" onClick={removeImageDB}><FontAwesomeIcon
                                    icon={["fas", "check"]}/>Yes
                                </button>
                                <button className="btn-cross" onClick={(e) => photoConfirmationPopUp(e)}><FontAwesomeIcon
                                    icon={["fas", "times"]}/>No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PhotoInputStateContextProvider>
    );
});
