import React from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const InputHeader = ({type="text", fieldname = '', description = '', fieldid = "",  fieldcss = {}})=>{

    const {t,i18n} = useTranslation(),
        displayname = description && description !== '' ? description : fieldname;

    return (
        <Form.Group className="row">
            <div className="col-12 text-center">
                <h4 className="m-0">{fieldname}</h4><span>{description}</span>
            </div>
        </Form.Group>
    );

}


export default InputHeader;
