import React, {useCallback} from 'react';
import {useDeliveryForm} from "../services/DeliveryFormContext";

const UseDeliveriesInputHandler = () => {

    const {_ , dispatch} = useDeliveryForm();

    const handleChange = useCallback((e) => {
        const target = (e.target.className === "yesnoswitch-switch" || e.target.className === "yesnoswitch-inner") ? e.currentTarget.firstChild : e.target,
            field_value = target.type === 'checkbox' ? target.checked : (target.value ?? e.detail),
            guid = target.getAttribute("data-guid");
        dispatch({type: 'update_extra', payload: {guid: guid, value: field_value}});
        if (e.persist)
            e.persist();
        }, []);

    return {handleChange}
}

export default UseDeliveriesInputHandler;
