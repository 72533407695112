import React, {useEffect, useState} from "react";
import { push } from 'connected-react-router';
import {UseLocalStorage} from "../services/UseLocalStorage";
import {Redirect, useHistory} from "react-router-dom";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faTruck,faClipboardList,faClipboardCheck,faPlusCircle,faMousePointer} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStateIfMounted} from "use-state-if-mounted";
import LoadingCircle from "../components/LoadingCircle";
import {useDepServices} from "../services/DepServicesContext";
import {MSOGAPIClient, MSOGAPIError} from "../utilitis/MSOGAPIClient";
import {
    buildHeader,
    createAsyncFetchAction,
    isInStandaloneMode,
    notify,
    updateApplayerTracking
} from "../utilitis/helper";
import {useOnlineStatus} from "../utilitis/useOnlineStatus";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useSessionService} from "../services/SessionService";
import {
    selectAppSettings, selectDeliveryListUpdateTime,
    selectLoadingState,
    selectQueryParams,
    selectSyncingState,
    selectUserQueues
} from "../store";
import {createFetchAppSettings} from "../reducers/appStateReducer";
import {setLoadingStatus} from "../reducers/runtimeStateReducer";
import {SET_MSOG_API_ERROR, UPDATE_USERQUEUES} from "../constains/appStateActions";
import {Button, Col, Container, ListGroup, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {element} from "prop-types";
import {isIOS, isMobile, isTablet} from "react-device-detect";
import UseLogoutWhenUnAuth from "../components/UseLogoutWhenUnAuth";

library.add(fas, faTruck,faClipboardList,faClipboardCheck,faPlusCircle,faMousePointer);

export const Jobs = (ref, callback) =>{

    const dispatch = useDispatch();
    const store = useStore();
    const {handleUnAuth} = UseLogoutWhenUnAuth();
    const client = new MSOGAPIClient(store);
    client.setUnauthorisedHandler(handleUnAuth);

    const query = new URLSearchParams(useSelector(selectQueryParams));
    const embeddedInAppLayer = query.get('isEmbeddedInAppLayer') === 'true';

    const sessionService = useSessionService();
    const {deliveriesService} = useDepServices();
    const appSettings = useSelector(selectAppSettings);
    const userQueues = useSelector(selectUserQueues);

    const [cacheUserQueuesUpdateCnt, setCacheUserQueuesUpdateCnt] = useState(userQueues.update_cnt);
    const [showEnableTrackingConfirm, setShowEnableTrackingConfirm] = useState(false);
    const [completedJobCount, ongoingJobCount] = deliveriesService.getLocalJobCounts()
    const isLoading = useSelector(selectLoadingState);
    const isSyncing = useSelector(selectSyncingState);

    const {t} = useTranslation();

    const fetchSettingData = async () => {

        dispatch(setLoadingStatus(true));

        dispatch(createFetchAppSettings(sessionService, client, response => {
            if (response.type === SET_MSOG_API_ERROR) {
                if (response.payload.code === 401) {
                    handleUnAuth();
                } else if (userQueues.update_cnt === undefined) {
                    dispatch(push('/error', response));
                }
            }
            dispatch(setLoadingStatus(false));
        }));
    }

    const fetchUserQueues = async () => {

        setCacheUserQueuesUpdateCnt(userQueues.update_cnt);

        dispatch(setLoadingStatus(true));

        dispatch(createAsyncFetchAction(UPDATE_USERQUEUES, sessionService, client, client.getUserQueues, response => {

            if (response.type === SET_MSOG_API_ERROR) {
                if (response.payload.code === 401) {
                    handleUnAuth();
                } else if (userQueues.update_cnt === undefined) {
                    dispatch(push('/error', response));
                }
            }
            dispatch(setLoadingStatus(false));
        }));
    }


    useEffect(()=> {

        if ( appSettings.update_cnt !== undefined && sessionService.isLogin()) {
            fetchUserQueues();
        }

    }, []);

    useEffect(()=> {

        if ( appSettings.update_cnt === undefined && sessionService.isLogin()) {

            fetchSettingData();

        } else if ( sessionService.isLogin() && userQueues.update_cnt === undefined ){

            fetchUserQueues();

        }

    },[userQueues.update_cnt, appSettings.update_cnt]);

    const seque = () => {
        if (!sessionService.isVehicleCheckDone()) {
            dispatch(push('/vehicle'));
        } else {
            dispatch(push('/deliveries'));
        }
    }

    const enableTrackingHandler = () => {
        sessionService.updateTracking("on")
        setShowEnableTrackingConfirm(false)
        seque()
    }

    const favHandler = (e,queue) => {
        e.preventDefault();

        if ( ongoingJobCount + completedJobCount > 0  && sessionService.getDeliveredQueue() !== false) {
            notify('error', 'select_queue.job_not_completed');
        }  else if (isLoading || isSyncing) {
            notify('error', 'select_queue.sync_not_completed');
        } else {
            sessionService.setDeliveredQueue(queue);
            if ( (isTablet || isMobile) && isInStandaloneMode() && ~sessionService.getTrackingPromptState() && appSettings.enable_applayer_tracking ) {
                setShowEnableTrackingConfirm(true);
            } else {
                sessionService.updateTracking("on")
                seque()
            }
        }
    }

    const favJobList = () =>{

        let selectedQueue = sessionService.getDeliveredQueue();

        const favList = userQueues.data.filter(key => {
            return selectedQueue !== false && key.guid === selectedQueue.guid;
        });

        const renderFavList = favList.map((item,index) => {
            return(
                <ListGroup.Item id={`${item.guid}`} key={`${item.guid}`} active className="p-3">
                    <Row>
                        <Col className="job-header">
                            <FontAwesomeIcon icon={["fas", "star"]}/>
                            <h3>{item.name}</h3>
                        </Col>
                    </Row>
                </ListGroup.Item>
                // <li id={"joblist" + index} key={index} className="active p-3">
                //     <div className="job-header d-flex flex-row align-items-center justify-content-center m-0 pt-1 mb-3">
                //         <FontAwesomeIcon icon={["fas", "star"]}/>
                //         <h3>{item.name}</h3>
                //     </div>
                //
                //     <div className="d-flex flex-row pl-3 pr-3 pb-2 w-75 m-auto job-indicators justify-content-between">
                //         <div className={item.new_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}>
                //             <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                //             <span className="job-stat">{item.new_job_count}</span>
                //         </div>
                //         <div className={item.new_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}>
                //             <FontAwesomeIcon icon={["fas", "truck"]}/>
                //             <span className="job-stat">{item.new_job_count}</span>
                //         </div>
                //         <div className={item.new_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}>
                //             <FontAwesomeIcon icon={["fas", "clipboard-check"]}/>
                //             <span className="job-stat">{item.new_job_count}</span>
                //         </div>
                //     </div>
                // </li>
            );
        });

        const emptyList = (<ListGroup.Item variant="dark" className="p-3">
            <Row>
                <Col>
                    {t("ui:jobs.message_no_queue_found")}
                </Col>
            </Row>
        </ListGroup.Item>);

        return (<ListGroup>{ userQueues.data.length > 0 ? renderFavList : emptyList }</ListGroup>);
    }

    const renderList =  () => {

        const selectedQueueGuid = sessionService.getDeliveredQueue() ? sessionService.getDeliveredQueue().guid : undefined;

        const notFavList = userQueues.data.filter((key) => {
            if((selectedQueueGuid === false || key.guid !== selectedQueueGuid) && key["guid"] !== null){
                return key.guid;
            }
        });

        const selectedQueue = userQueues.data.find( element => element.guid === selectedQueueGuid);

        const emptyList = <ListGroup.Item variant="dark" className="p-3">
            <Row>
                <Col>
                    {t("ui:jobs.message_no_queue_found")}
                </Col>
            </Row>
        </ListGroup.Item>;

        const renderQueueOption = (item, isFav = false) => {
            return (
                <ListGroup.Item id={`${item.guid}`} key={`${item.guid}`} active={isFav} className="p-3" style={{background: isFav ? '':'floralwhite' }}>
                    <Row>
                        <Col xs={12} md={5} className="mb-2 align-self-center">
                            {isFav ? <FontAwesomeIcon icon={["fas", "star"]}/>:<></> }
                            <h3 className="queue-name">{item.name}</h3>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={4}>
                                    <div className={`job-indicators ${item.new_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}`}>
                                        <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                                        <span className="job-stat">{item.new_job_count}</span>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className={`job-indicators ${item.ongoing_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}`}>
                                        <FontAwesomeIcon icon={["fas", "truck"]}/>
                                        <span className="job-stat">{item.ongoing_job_count}</span>
                                    </div>
                                </Col>
                            {isFav &&
                                <Col xs={4}>
                                    <div className={`job-indicators ${item.completed_job_count > 0 ? "border-rnd bg-yellow-dark" : "border-rnd bg-grey-light"}`}>
                                        <FontAwesomeIcon icon={["fas", "clipboard-check"]}/>
                                        <span className="job-stat">{item.completed_job_count}</span>
                                    </div>
                                </Col>
                            }
                            {!isFav &&
                                <Col xs={4}>
                                    <button className="btn-select" disabled={isLoading} onClick={(e) => favHandler(e,item)}>
                                        <FontAwesomeIcon icon={["fas", "mouse-pointer"]}/>
                                        <span>Queue</span>
                                    </button>
                                </Col>
                                    }

                            </Row>
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        };

        const favItem = selectedQueue ? renderQueueOption(selectedQueue, true): <></>;

        const renderNonFavList = notFavList.map((item, index) => {
            return (renderQueueOption(item));
        });

        return (
            <div className="data-list-content queue-selection-content">
                <ListGroup>
                    {favItem}
                    { userQueues.data.length > 0 ? renderNonFavList : emptyList }
                </ListGroup>
            </div>);
    }

    return (
        <Container fluid>
            <div className="data-list">
                <div className="data-list-header"><h3>{t("ui:jobs.header")}</h3></div>
                {(userQueues.update_cnt === undefined)  ? <div className="data-loading"><h3>Loading... </h3><LoadingCircle isSubmitting={userQueues.update_cnt === undefined} /></div>
                    : renderList()}
            </div>
            <Modal show={showEnableTrackingConfirm} onHide={seque} centered backdrop="static">
                <Modal.Body><h5>{(sessionService.getTrackingPromptState() === 0) ? t("ui:tracking.message_active") : t("ui:tracking.message_update")}</h5></Modal.Body>
                <Modal.Footer >
                    <Button variant="primary" onClick={enableTrackingHandler}>
                        <FontAwesomeIcon icon={["fas", "check"]}/> {t("ui:generic.button.okay")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>);
};

export default Jobs;
