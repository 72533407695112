import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {isBrowser} from "react-device-detect";
import {useTranslation} from "react-i18next";

const TakePicBtn = ({objImages,imageLimitStorage, fieldid, editable = true, handler = () => {}}) =>{

    const [photoCount, setPhotoCount] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        setPhotoCount(objImages(fieldid));
    }, [objImages(fieldid)]);


    return(<button className="btn-camera mt-3" type="button" disabled={photoCount > imageLimitStorage - 1}  onClick={handler} data-guid={fieldid} disabled={(editable) ? "" : "disabled"}>
        <FontAwesomeIcon icon={["fas", "camera"]} inverse/>
        <span>{t(`ui:generic.photo_button.${(photoCount > imageLimitStorage - 1) ? 'disable' : ((photoCount > 0) ? 'more' : 'take')}`)}</span>
    </button>);
}

export default TakePicBtn;
