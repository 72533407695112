


export const DELIVERY_TYPE_SINGLE = 'single';
export const DELIVERY_TYPE_GROUP = 'group';

export const DISPLAY_MODE_DARK = 'dark';
export const DISPLAY_MODE_LIGHT = 'light';

export const LOCAL_TAG_REMEMBER_ME = "rememeber_me";
export const LOCAL_TAG_QUERY_PARAMS = "query_params";