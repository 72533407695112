import { element } from "prop-types";

const precheckFormReducer = (state, action) => {
    if (action.type === 'initial_value') {
        return action.payload.map( element => {return {...element, value: null}});
    } else if (action.type === "update_item") {
        const itemIdx = state.findIndex( element => element.name === action.payload.id);
        if (itemIdx !== -1 && state[itemIdx].value !== action.payload.value) {
            state[itemIdx] = {...state[itemIdx], value: action.payload.value};
        }
        return state;
    } else {
        throw new Error(`Unhandled action type: ${action.type}`);
    }

}

export default precheckFormReducer;
