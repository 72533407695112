import React, {useRef} from "react";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

library.add(fas,faTrash);

const PhotoInputPreview = ({className, fieldid, fields, register, required, removeHandler}) => {

    const renderPreviews = () => {
        if(fields.length > 0) {
            return fields.map( (item, index) => {
                return ((item.id) ?
                        <div key={item.id} className="image-preview">
                            <button type="button" className="btn-delete-img" onClick={(e) => removeHandler(e, index)}>
                                <FontAwesomeIcon icon={["fas", "trash"]}/>
                            </button>
                            <img src={`${!/^data:image\/png;base64,/.test(item.value) ? 'data:image\\/png;base64,' : ''}${item.value}`} className="img-fluid" alt={"photo-" + item.id}/>
                            <input
                                type="hidden" {...register(`${fieldid}.${index}.value`, {required: required == 1})} value={item.value} />
                        </div> : ""
                    )
            });
        }
        else
        {
            return <input type="hidden" {...register(`${fieldid}.0.value`, { required: required == 1})} />;
        }
    };

    return (
        <div className={className}>
            {renderPreviews()}
        </div>
    );
}

export default PhotoInputPreview;
