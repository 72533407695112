import React from "react";

import { useMessageContext, REMOVE } from "../services/MessageContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fas, faTimes,faCheck,faInfo,faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from 'react-i18next';
library.add(fas,faTimes,faCheck,faInfo,faExclamationTriangle);


export default function Message({ message }) {
    const { messageDispatch } = useMessageContext();
    const { t, i18n } = useTranslation('message');

    function renderItem(content) {

        if (typeof content === "function") {
            return content();
        } else if (typeof content === "string") {
            return <p>{t(content)}</p>;
        } else {
            return <p>{JSON.stringify(content, null, 2)}</p>;
        }
    }
    return (
        <div className="message">
            <div className="message-container">
                {message.map(item => {
                    if(item.type === "error"){
                        return (
                            <div className={`message-container-item ${item.type ? item.type : ""}`} key={item.id}>
                                <div className="message-icon">
                                 <FontAwesomeIcon icon={["fas", "times"]} />
                                </div>
                                <h3>Error</h3>
                              <span role="img" aria-label="close message" className="message-close"
                                    onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>
                                  <FontAwesomeIcon icon={["fas", "times"]}/>
                              </span>
                                {renderItem(item.content.message)}
                                <button className="btn-pop-up" onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>Okay</button>
                            </div>
                        );
                    }else if(item.type === "success"){
                        return (
                            <div className={`message-container-item ${item.type ? item.type : ""}`} key={item.id}>
                                <div className="message-icon">
                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                </div>
                                <h3>Success</h3>
                                <span role="img" aria-label="close message" className="message-close"
                                      onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>
                                  <FontAwesomeIcon icon={["fas", "times"]}/>
                              </span>
                                {renderItem(item.content.message)}
                                <button className="btn-pop-up" onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>Okay</button>
                            </div>
                        );
                    }else if(item.type === "info"){
                        return (
                            <div className={`message-container-item ${item.type ? item.type : ""}`} key={item.id}>
                                <div className="message-icon">
                                    <FontAwesomeIcon icon={["fas", "info"]} />
                                </div>
                                <span role="img" aria-label="close message" className="message-close"
                                      onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>
                                  <FontAwesomeIcon icon={["fas", "times"]}/>
                              </span>
                                {renderItem(item.content.message)}
                                <button className="btn-pop-up" onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>Okay</button>
                            </div>
                        );
                    }else if(item.type === "warning"){
                        return (
                            <div className={`message-container-item ${item.type ? item.type : ""}`} key={item.id}>
                                <div className="message-icon">
                                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
                                </div>
                                <span role="img" aria-label="close message" className="message-close"
                                      onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>
                                  <FontAwesomeIcon icon={["fas", "times"]}/>
                              </span>
                                {renderItem(item.content.message)}
                                <button className="btn-pop-up" onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>Okay</button>
                            </div>
                        );
                    }else{
                        return (
                            <div className={`message-container-item ${item.type ? item.type : ""}`} key={item.id}>
                              <span role="img" aria-label="close message" className="message-close"
                                    onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>
                                  <FontAwesomeIcon icon={["fas", "times"]}/>
                              </span>
                                {renderItem(item.content.message)}
                                <button className="btn-pop-up" onClick={() => messageDispatch({ type: REMOVE, payload: { id: item.id } })}>Okay</button>
                            </div>
                        );
                    }

                })}
            </div>
        </div>
    );
}
