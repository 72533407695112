import {UseLocalStorage} from "../services/UseLocalStorage";
import {useEffect} from "react";
import {isIOS} from "react-device-detect";

const UseScreenOrientation=()=>{

    const getOrientation = () => window.screen.orientation.type;
    const getOrientationSafari = () => {
        let mql = window.orientation;

        if(mql === 90){
            return "landscape-primary";
        }else{
            return "portrait-primary";

        }

    };

        const [orientation, setOrientation] = UseLocalStorage("orientation", "portrait-primary");

        const updateOrientation = () => {

        if(!isIOS) {
            setOrientation(getOrientation())
        }

        if(isIOS){
            setOrientation(getOrientationSafari())
            }
        }

        useEffect(() => {
                  window.addEventListener('orientationchange', updateOrientation)
            return () => {
                  window.removeEventListener('orientationchange', updateOrientation)
            }
        }, [orientation]);

        return orientation;

}

export default UseScreenOrientation;
