const axios = require("axios");

const DEFAULT_MSOG_AUTH_URL = "https://msog-dev.udocs.cloud:8001";
const DEFAULT_MSOG_API_PROTOCOL = "https";

const HTTP_CODE_OK = 200;
const HTTP_CODE_TIMEOUT = 408;

const MSOG_LOGIN_PATH = "/api/v1/user/login";
const MSOG_REFRESH_TOKEN_PATH = 'api/v1/user/refreshtoken';
const MSOG_GET_APPSETTINGS = '/api/v1/settings/getappsettings';
const MSOG_GET_NEWJOBCNT = '`/api/v1/job_queues/${queueGuid}/getnumberofnewjobs`';
const MSOG_GET_NEWJOBINQUEUE = '`/api/v1/job_queues/${queueGuid}/getnewjobs`';
const MSOG_GET_PRECHECKLIST = '/api/v1/settings/getvehiclechecklist';
const MSOG_GET_USERQUEUE = '/api/v1/job_queues/getuserqueues';
const MSOG_GET_LOGOUT = '/api/v1/auth/logout';

const MSOG_PING = '/api/ping';
const MSOG_POST_MARKJOBSASCOMPLETED = '/api/v1/job/markjobsascompleted';
const MSOG_POST_SETJOBSTODISPATCHED = 'api/v1/job/setjobstodispatched';
const MSOG_POST_PUSHJOBSBACK = '/api/v1/job/pushjobsbacktoqueue';
const MSOG_POST_SAVEDRIVERLOGS = 'api/v1/driver/savedriverlogs';
const MSOG_POST_SENDMESSAGE = 'api/v1/notification/sendmessage';
const MSOG_POST_SAVELOCS = 'api/v1/driver/savelocs';
const MSOG_POST_DEVICEINFO = 'api/v1/auth/logdeviceinfo';
const MSOG_POST_ERRORLOGS = 'api/v1/app/logerrors'

const MSOG_REQUEST_AUTH = "AUTH";
const MSOG_REQUEST_API = "API";

export class MSOGAPIClient {

    static  MSOGAPI_ERROR = "MSOGAPIClientError";

    constructor(store) {
        this.store = store;
    }

    setUnauthorisedHandler(unauthorisedHandler) {
        this.unauthorisedHandler = unauthorisedHandler
    }

    updateToken(token = null) {
        this.token = token
    }

    getConnection(type) {
        const query = new URLSearchParams(this.store.getState().appStateReducer.queryParams),
            domain = query.get('domain') ?? window.location.hostname.replace('app.', ''),
            protocol = (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_API_PROTOCOL) ? process.env.REACT_APP_API_PROTOCOL : DEFAULT_MSOG_API_PROTOCOL,
            connection = ( type !== MSOG_REQUEST_AUTH) ? this.apiConnection ?? axios.create({
                baseURL: `${protocol}://${domain}` + ((process.env.NODE_ENV !== 'production') ? `:${process.env.REACT_APP_API_PORT}` : ''),
                timeout: 0,
                maxContentLength: 6000
            }) : this.authConnection ?? axios.create({
            baseURL: process.env.REACT_APP_AUTH_URL ?? DEFAULT_MSOG_AUTH_URL,
            timeout: 0,
            maxContentLength: 6000
             });

        if (type !== MSOG_REQUEST_AUTH) {
            this.apiConnection = connection;
        } else {
            this.authConnection = connection;
        }

        return connection;
    }

    request(type , method, path , token = null, data = null) {
        var headers = this.buildHeader(token),
            connection = this.getConnection(type),
            _self = this;
        return new Promise(async function(resolve) {
                var response;
                try {
                    response = (method === 'get') ? await connection.get(path + ((data === null) ? '' : `?${data}`), headers) : await connection.post(path, data, headers);
                } catch (error) {
                    response = error.response ?? {status:HTTP_CODE_TIMEOUT, statusText:'timeout'};
                } finally {
                    resolve( _self.processHTTPResponse(response));
                }
            });
    }


    processHTTPResponse(response) {
        if (response.status === HTTP_CODE_OK && response.data.success) {
            return response.data.success.data ;
        } else {
            var error = new MSOGAPIError(this.MSOGAPI_ERROR);
            error.text = response.statusText ?? '';
            error.code = response.status ?? 0;

            if (error.code === 401 && this.unauthorisedHandler !== undefined) {
                this.unauthorisedHandler()
            }

            return error;
        }
    }

    buildHeader(token: null) {
        var headers = {
            'Content-Type': 'application/json'
        }

        if (token !== null) {
            headers.Authorization = `Bearer ${token}`;
        }

        return {headers: headers};
    }

    postPasswordGrant(username, password, domain) {
        const payload = {
            name: username,
            password: password,
            domain: domain,
            app_id: process.env.REACT_APP_ID
        },
        _self = this;
        return new Promise(async function(resolve) {
            resolve( await _self.request(MSOG_REQUEST_AUTH, 'post', MSOG_LOGIN_PATH,null, payload));
        });
    }

    postTokenValidate(os,  ) {

    }

    refreshToken(token) {
        const payload = {
                refresh_token: token,
                app_id: process.env.REACT_APP_ID
            },
            _self = this;
        return new Promise(async function(resolve) {
            resolve( await _self.request(MSOG_REQUEST_AUTH, 'post', MSOG_REFRESH_TOKEN_PATH,null, payload));
        });
    }

    getPrecheckList() {
        const _self = this;
        return new Promise( async function(resolve) {
           resolve(await  _self.request(MSOG_REQUEST_API, 'get', MSOG_GET_PRECHECKLIST, _self.token))
        });
    }

    getAppSettings() {
        const _self = this;
        return new Promise(async function(resolve) {
            resolve( await _self.request(MSOG_REQUEST_API, 'get', MSOG_GET_APPSETTINGS, _self.token));
        });
    }

    getUserQueues() {
        const _self = this;
        return new Promise(async function(resolve) {
            resolve( await _self.request(MSOG_REQUEST_API, 'get', MSOG_GET_USERQUEUE, _self.token));
        });
    }

    ping() {
        const _self = this;
        return new Promise( async function(resolve) {
            resolve( await _self.request(MSOG_REQUEST_API, 'get', MSOG_PING));
        });
    }

    async logout() {
        return await this.request(MSOG_REQUEST_API, 'get', MSOG_GET_LOGOUT, this.token);
    }


    async getNewJobCount(queueGuid) {
        const apiURL = eval(MSOG_GET_NEWJOBCNT + ";");
        return await this.request(MSOG_REQUEST_API, 'get', apiURL, this.token);
    }

    async getNewJobInQueue(queueGuid) {
        const apiURL = eval(MSOG_GET_NEWJOBINQUEUE + ";");
        return await this.request(MSOG_REQUEST_API, 'get', apiURL, this.token);
    }

    async postMarkJobsAsCompleted(jobs) {
        const payload = {
                data: jobs,
            };
        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_MARKJOBSASCOMPLETED, this.token, payload);
    }

    async postSetJobsToDispatched(guids) {
        const payload = {
          guids: guids,
        };

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_SETJOBSTODISPATCHED, this.token, payload);
    }

    async postPushJobsBack(guids) {
        const payload = {
            guids: guids,
        };

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_PUSHJOBSBACK, this.token, payload);
    }


    async postSaveDriverLogs(logs) {
        const payload = {
            driverlogs: logs
        };

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_SAVEDRIVERLOGS, this.token, payload);
    }

    async postSaveLocations(locations) {
        const payload = {
            locations: locations,
        }

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_SAVELOCS, this.token, payload);
    }

    async postLogDeviceInfo(appBuid, userAgent) {
        const payload = {
            app_build: appBuid,
            user_agent: userAgent
        }

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_DEVICEINFO, this.token, payload);
    }

    async postSendMessage(lat, long, jobGuid, queueGuid, fullAddress, mobile, isDelay) {

        const payload = {
            curr_lat: lat,
            curr_long: long,
            next_job_guid: jobGuid,
            queue_guid: queueGuid,
            full_address: fullAddress,
            mobile: mobile,
            is_delay: isDelay
        };

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_SENDMESSAGE, this.token, payload);
    }


async postErrorLogs(errors) {
        
        const payload = {
            errors: errors,
        };

        return await this.request(MSOG_REQUEST_API, 'post', MSOG_POST_ERRORLOGS, null, payload);
    }

}

export class MSOGAPIError {}
