import React from "react";
import {Link} from "react-router-dom";

const PageNotFound = ()=>{
        return(<div className="w-100 form-login-holder maxw-680">
                    <h2>Page Not Found</h2>
                    <p>The page you're trying to access is not found.</p>
                    <Link className="btn btn-blue-base p-2 btn-def-rnd w-100 btn-login" to="/login">Return to Login</Link>
                </div>);

}

export default PageNotFound;