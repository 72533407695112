import {
    UPDATE_SETTINGS,
    SET_VEHICLE_CHECK,
    SET_ONLINE_STATUS,
    SET_BACKGROUND_SERVICE_STATUS,
    SET_LOGIN_STATUS,
    SET_IS_QUEUE_SELECTED,
    SET_IS_VEHICLE_CHECKED,
    SET_IS_CAMERA_OPEN,
    RESET_APP_STATE,
    SET_LOADING_STATUS,
    SET_SYNCING_STATUS,
    SET_DISPLAY_MODE, SET_QUERY_PARAMS
} from "../constains/appStateActions";
import {MSOGAPIClient} from "../utilitis/MSOGAPIClient";
import {buildMiddlewareActionFromAPI, createAsyncFetchAction, updateState} from "../utilitis/helper";
import {DISPLAY_MODE_LIGHT} from "../constains/types";

const defaultAppSettings = {
    max_precheckphoto_number: 2,
    max_deliveryphoto_number: 2,
    photo_resolution: {height:600, width:800},
    lineitem_pagination_itemperpage: 5,
    max_lineitemphoto_number: 1,
    gps_tracking_interval: 300,
};

export const InitialAppState = {
    appSettings: defaultAppSettings,
    isVehicleCheckRequired: false,
    isLogin: false, // deprecated since 0.10.1
    isQueueSelected: false,
    isVehicleChecked: false,
    displayMode: DISPLAY_MODE_LIGHT,
    queryParams: window.location.search
};

export function appStateReducer(state = {}, action) {

    switch (action.type) {
        case UPDATE_SETTINGS:
            return JSON.stringify(state.appSettings) !== JSON.stringify(action.payload) ? {...state,
                appSettings: { ...state.appSettings, ...action.payload, update_cnt: (state.appSettings.update_cnt) ? state.appSettings.update_cnt + 1 : 1}
            } : state;
        case SET_LOGIN_STATUS: // deprecated since 0.10.1
        case SET_VEHICLE_CHECK:
        case SET_IS_VEHICLE_CHECKED:
        case SET_IS_QUEUE_SELECTED:
        case SET_DISPLAY_MODE:
        case SET_QUERY_PARAMS:
            return updateState(state, action.type, action.payload);
        case RESET_APP_STATE:
            return {...InitialAppState, displayMode: state.displayMode, queryParams: state.queryParams};
    }

    return state;
};

export function createFetchAppSettings(sessionService, client, callback = undefined) {
    return createAsyncFetchAction(UPDATE_SETTINGS, sessionService, client, client.getAppSettings, callback);
};

export function createLogout(sessionService, client, callback = undefined) {
    return createAsyncFetchAction(RESET_APP_STATE, sessionService, client, client.logout, callback, true);
};

export function setVehicleCheck(value) {
    return {
        type:SET_VEHICLE_CHECK,
        payload: value
    }
};

export const setQueueSelectedStatus = value => { return {type: SET_IS_QUEUE_SELECTED, payload: value}; };
export const setVehicleCheckedStatus = value => { return {type: SET_IS_VEHICLE_CHECKED, payload: value}; };
export const setDisplayMode = value => { return {type: SET_DISPLAY_MODE, payload: value};};
export const setQueryParams = value => { return {type: SET_QUERY_PARAMS, payload: value};};