import {useDispatch, useSelector} from "react-redux";
import {selectSWRegistration, selectSWUpdateState, selectSyncingState} from "../store";
import {useEffect} from "react";
import {setIsSWUpdate} from "../reducers/runtimeStateReducer";

const UseAutoUpdate = (restrictedFlag) => {

    const isServiceWorkerUpdated = useSelector(selectSWUpdateState);
    const serviceWorkerRegistration = useSelector(selectSWRegistration);
    const dispatch = useDispatch();

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.addEventListener('statechange', e => {
                dispatch(setIsSWUpdate(false, null));
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        }
    };

    useEffect(()=>{

        if (isServiceWorkerUpdated && !restrictedFlag) {
            updateServiceWorker();
        }

    }, [isServiceWorkerUpdated, restrictedFlag]);


    return {isServiceWorkerUpdated};
}

export default UseAutoUpdate;