import React from "react";
import { isIOS } from "react-device-detect";
import { useStateIfMounted } from "use-state-if-mounted";
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';

import LoadingCircle from "../components/LoadingCircle";

import {setRememberMe} from "../reducers/appStateReducer";
import {useSessionService} from "../services/SessionService";
import {Button, Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {useTranslation, Trans} from "react-i18next";
import {UseLocalStorage} from "../services/UseLocalStorage";
import useWebInstallPrompt from "../components/UseWebInstallPrompt";
import {LOCAL_TAG_REMEMBER_ME} from "../constains/types";
import useAutoUpdate from "../components/UseAutoUpdate";
import {selectQueryParams} from "../store";

const moment = require('moment');

const Login = () =>{

    const dispatch = useDispatch();
    const sessionService = useSessionService();
    const query = new URLSearchParams(useSelector(selectQueryParams));
    const embeddedInAppLayer = query.get('isEmbeddedInAppLayer') === 'true';
    const domain =decodeURI(query.get('domain') ?? window.location.hostname.replace('app.', ''));
    const [username, setUsername] = useStateIfMounted(sessionService.getUserName());
    const [password, setPassword] = useStateIfMounted("");
    const [isSubmitting,setIsSubmitting] = useStateIfMounted(false);
    const {isServiceWorkerUpdated} = useAutoUpdate(isSubmitting);
    const [isLoginInvalid,setIsLoginInvalid] = useStateIfMounted(false);
    const {t} = useTranslation();
    const [rememberMe, setRememberMe] = UseLocalStorage(LOCAL_TAG_REMEMBER_ME, false);
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    const PostPasswordGrant = async (e)=>{
        e.persist();
        setIsSubmitting (true);
        const value = await sessionService.postPasswordGrant(username, password, domain);
        if (value === true) {
            setIsLoginInvalid( false);
            JobsRedirect();
        } else {
            //notify('error', `login.${value.code}`);
            setIsLoginInvalid( true);
            setIsSubmitting (false);
        }
        return value;
    }

    const InstallPWA = () => {

        return ( !embeddedInAppLayer && webInstallPrompt &&
            <Modal show={true} centered={true}>
                <Card>
                    <Card.Header className={"text-center"} style={{backgroundColor: '#fff'}}>
                    <img
                        className="mx-auto"
                        style={{
                            borderTopRightRadius: '50%',
                            borderTopLeftRadius: '50%',
                            backgroundColor: '#fff',
                            marginTop: '-50px'
                        }}
                        width="100px"
                        src="logo192.png"
                        alt="Icon"
                    />
                    </Card.Header>
                    <Card.Title className="text-center">
                        <h3>Install App</h3>
                    </Card.Title>
                    <Container>
                        <Row className="mb-1">
                            <Col xs={12} className="text-center">
                                <div dangerouslySetInnerHTML={
                                    {__html: t("ui:install_prompt." + ((isIOS) ? 'ios' : 'android'), { icon: '<img width="15" height="18" src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADIAAAA8CAYAAAAkNenBAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAzfHTVMAAAG2SURBVGje7drtkYMgEAZgS7CElGAJlpASKMES3g4owRKuBEqwhJRgCebPxuPIAn6AyM1mZmcyyQzZRwQ3rs2yLM1/iN83GV4ANICFQuX4jewQAKOFyIbJCvEgPjFWAYkgkmOyQDYikmKSQ3YikmGSQQC0AAy3sLnEU2OSQAgxeRLu3M8DMzcWg4QQ9H3PQVJjUkBMKBkOAqCLHARdAhI8oh5IH5lRUwJilx8TgHYPxIPRpRZ77ya3B2JhFIDnXWutTZAaikaBJIXQBQ0AfmirDYU6sUZUZGxNeSh3QwlCCGB21kw4AcHe+swGsRBCzAeKvyshf7Z7H+R1YNASkPXi+QXxVKsLzVDJNTIFMB0HGWPnY6ldC8DDAwIHcRf4607bL1UA7vo1HOR0JZr7OsKcNZsguCEEAhGIQAQiEIEIRCACWUtvd8y2Ooj1p2mmUAnGKwPJcItJIAIRiEAEIhCBCEQg+SB6C+R08/4CyLQFYq54dO8EYuDaHhxkCPTs1OfhgAIxBHqaioO0B/uHpWIG0Pp6iKoiyLNpwu1pVcFMrGs3+MAA3TjQkUbk1TFRTg871y9I7fEG3v0L9gcXn/0AAAAASUVORK5CYII="></img>', interpolation: { escapeValue: false } })}}>
                                </div>
                            </Col>
                        </Row>
                        <Row className={`${isIOS ? 'justify-content-center': 'justify-content-around'} mb-1`}>
                            { !isIOS && <Col xs={3} className="text-center">
                                <Button color="primary" onClick={handleWebInstallAccepted}>
                                    Install
                                </Button>
                            </Col>}
                            {/*<Col xs={3} className="text-center">*/}
                            {/*    <Button onClick={handleWebInstallDeclined}>Close</Button>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </Card>
            </Modal>
        );
    };

    const handleUserName = e => {
        setUsername(e.target.value);
        setIsLoginInvalid( false);
    };

    const handlePassword = e => {
        if(!isIOS) {
            if (e.target.value === " ") {
                e.target.style = " ";
            } else {
                e.target.style.fontFamily = "text-security-disc";
            }
        }
        setPassword(e.target.value);
        setIsLoginInvalid( false);
    };

    const JobsRedirect = () => {
        //setPathState('/jobs');
        //history.push('/jobs');
        dispatch(push('/jobs'));
    }

    const handleRememberMe = e =>{

        setRememberMe(e.target.checked);

    }

    const LoginForm = () => {
        return (
            ! sessionService.isLogin() &&
            <Container fluid>
                <Form className="form-login">
                    <Form.Group controlId="formUserName" as={Row} className="mb-3 justify-content-center">
                        <Col md="10" lg="6" xxl="6">
                            <Form.Control className={isLoginInvalid ? 'is-invalid' : ''} type="text" size="lg" placeholder={t('ui:login.ph_input_username')} value={username} onChange={handleUserName} disabled={isSubmitting}/>
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="formPassword" as={Row} className="mb-3 justify-content-center">
                        <Col md="10" lg="6" xxl="6">
                            <Form.Control className={isLoginInvalid ? 'is-invalid' : ''} type="password" size="lg" placeholder={t('ui:login.ph_input_password')} value={password} onChange={handlePassword} disabled={isSubmitting}/>
                            <Form.Text className="invalid-feedback">{t("ui:login.message_invalid_login")}</Form.Text>
                        </Col>
                    </Form.Group>

                    <div className="remember-me-holder page-header">
                        <div>
                            <input id="remember-me" type="checkbox" onChange={handleRememberMe} checked={rememberMe} disabled={isSubmitting}/>
                            <label htmlFor="remember-me"> {t("ui:login.lbl_remember_me")}</label>
                        </div>
                    </div>

                    <button type="button" className={`btn-login ${isSubmitting ? 'active': ''}`} onClick={(e)=>PostPasswordGrant(e)} disabled={isSubmitting || username.length === 0 || password.length === 0 || isServiceWorkerUpdated}>
                        <span>{isSubmitting? "Loading ...": "Login"}</span>
                        <LoadingCircle isSubmitting={isSubmitting} />
                    </button>
                </Form>
            </Container>);
    }
    return(
        <>
            {LoginForm()}
            {InstallPWA()}
        </>
    );
};

export default Login;
