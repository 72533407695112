import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {globalToLocalFieldGrid} from "../../utilitis/helper";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const DropDown = forwardRef(({fieldname, description = "", fieldvalue = "", fieldlogic = "", fieldid = "", required = '0', editable = true,  fieldcss = {}, handler = () => {}, register = () => {}, errors = () => {}, setValue = () => {}, filter = ""}, ref) =>{

    let dropdownValues = JSON.parse(fieldlogic) ?? [];
    const [labelCol, fieldCol, labelColTablet, fieldColTablet] = globalToLocalFieldGrid(fieldcss);
    const {t} = useTranslation();
    const displayname = description && description !== '' ? description : fieldname;
    const inputRef = useRef();
    const validateParams = (required instanceof String || required instanceof Number) ? {required: Number(required) !== 0} : required;


    useEffect(() => {
        inputRef.current.dispatchEvent(new Event('change', { bubbles: true}));
    }, []);

    const optionTags = ()=>{
        let tagList = [];
        dropdownValues.map((option,index) => {
            if ( filter === "" || option[filter] ) {
                tagList.push(<option key={index} id={`${fieldid}-${index}`}
                                     value={option.value}>{option.display ? option.display : option.value}</option>);
            }
            return "";
        })
        return tagList;
    }

    useImperativeHandle(ref, () => ({
        updateValue(value) {
            inputRef.current.value = value;
            inputRef.current.dispatchEvent(new Event('change', { bubbles: true}));
        },
    }))

    const onValueChange = (e) => {
        setValue(fieldid,e.target.value);
        handler(e)
    }

    return (
        <Form.Group className="row">
            <div className={`col-${labelCol} col-md-${labelColTablet} text-left`}>
                <label htmlFor={fieldid} className="ps-3">{displayname}{required === 1 || (required instanceof Object && (required.required || required.validate !== undefined) ) ? ' *' : ''}</label>
            </div>
            <div className={`col-${fieldCol} col-md-${fieldColTablet}`}>
                <select className={`form-select form-control ${errors && errors[fieldid] ? "is-invalid" : ""}`} id={fieldid}
                        data-guid={fieldid}  {...register(fieldid, validateParams)} defaultValue={fieldvalue} onChange={onValueChange} ref={inputRef} disabled={(editable) ? "" : "disabled"}>
                    {optionTags()}
                </select>
                <div className="invalid-feedback">{t("ui:generic.dropdown.invalid_message", {name: fieldname})}</div>
            </div>
        </Form.Group>
    );
});

export default DropDown;
