import React, {useContext, createContext, useReducer} from 'react';
import {element} from "prop-types";

const MSOGFormContext = createContext(null);

export const MSOGFormContextProvider = (props) => {

    const formValues = props.formValues,
        dispatch = props.dispatch,
        value = {formValues, dispatch}

    return (
        <MSOGFormContext.Provider value={value}>
            {props.children}
        </MSOGFormContext.Provider>
    );
};

export const useMSOGForm = () => useContext(MSOGFormContext);
