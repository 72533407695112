import React from "react";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {goBack} from "connected-react-router";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

const Error= props =>{

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const retry = () => {
        dispatch(goBack());
    }

    return(<div>
       <h2 className="bg-red-warning border-rnd text-white p-2">Login Error!</h2>
       <p>{t(`message:${props.location.state.payload}`)}</p>
       <Button variant="light" className="p-2 btn-def-rnd w-100" onClick={retry}>{t('ui:generic.button.retry')}</Button>
   </div>);
}

export default Error;