
const RemoveFocusWhenNotTab = () => {

    const handleFirstTab = (event) => {
        if (event.keyCode === 9) {
            document.body.classList.add('user-is-tabbing');
        }

    };

    const handleMouseMove = () => {
        document.body.classList.remove('user-is-tabbing');
    }

        window.addEventListener('keydown', handleFirstTab);
        window.addEventListener('mousemove',handleMouseMove);
        window.removeEventListener('keydown', handleFirstTab);
        window.removeEventListener('mousemove',handleMouseMove);
};

export default RemoveFocusWhenNotTab;