import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import React, {memo, useCallback, useEffect, useRef} from "react";
import UseWindowDimensions from "./DetectWindowSize/WindowDimensions";

const propsAreEqual = (prevProps, nextProps) => prevProps.src === nextProps.src;

const ImageViewer = memo(({src, imgWidth, imgHeight, scale}) => {

    const imgRef = useRef();
    const pinchZoomRef = useRef();

    const {height, width} = UseWindowDimensions();

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    },[]);

    useEffect( () => {
        pinchZoomRef.current.scaleTo({x: 0,
            y: 0,
            scale: 1})
    },[src]);

    return(
        <div style={{maxHeight:'100%'}}>
            <QuickPinchZoom ref={pinchZoomRef} onUpdate={onUpdate}>
                <img ref={imgRef} src={src} style={{maxWidth:'100%'}}/>
            </QuickPinchZoom>
        </div>
    );
}, propsAreEqual);

export default ImageViewer;