import React, {useState, useRef, useEffect} from "react";
import Measure from "react-measure";
import { useCardRatio } from "../../../components/Camera/UseCardRatio";
import {useUserMedia} from "../../../components/Camera/UseUserMedia";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Video, Canvas, Wrapper, Container, Flash, Button} from "../../../components/Camera/camerastyle";
import {useLineImage} from "../../../services/LineImageContext";
import Dexie from "dexie";
import {isTablet, isBrowser, isIOS, isMobileOnly} from "react-device-detect";
import useScreenOrientation from "../../../components/UseScreenOrientation";
import CaptureOptions from "../../../services/CaptureOptions";
import {usePhotoInputState} from "../../../services/PhotoInputStateContext";


const Camera = ({onImageCaptured})=>{

    const canvasRef = useRef();
    const videoRef = useRef();

    const {state, dispatch} = usePhotoInputState();

    const [container, setContainer] = useState({ width: 0, height: 0 });
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
    const [isFlashing, setIsFlashing] = useState(false);
    const orientation = useScreenOrientation();
    const mediaStream = useUserMedia(CaptureOptions());
    const [aspectRatio, calculateRatio] = useCardRatio(1.6);


    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

    const handleResize = (contentRect)=>{
        setContainer({
            width: contentRect.bounds.width,
            height: Math.round(contentRect.bounds.width / aspectRatio)
        });
    }

    const handleCanPlay =()=> {
        calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
        dispatch({type:"setIsVideoPlaying", payload: true});
        videoRef.current.play();
    }

    const handleCapture = (e)=> {
        const context = canvasRef.current.getContext("2d");


        canvasRef.current.width = container.width;
        canvasRef.current.height = container.height;
        if(isTablet && isIOS && orientation ==="portrait-primary"){
            context.drawImage(videoRef.current, 0, 0, 640, 480);
        }

        if(isTablet && isIOS && orientation ==="landscape-primary"){
            context.drawImage(videoRef.current, 0, 0, 640, 480);
        }

        if(isBrowser){
            context.drawImage(videoRef.current, 0, 0, container.width, container.height);
        }

        if(isMobileOnly){
            context.drawImage(videoRef.current, 0, 0, container.width, container.height);
        }

        setIsCanvasEmpty(false);
        setIsFlashing(true);
        cameraCapture(canvasRef.current.toDataURL());

        e.preventDefault();
        e.persist();
    }

    const handleClear = (e)=> {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setIsCanvasEmpty(true);
        e.preventDefault();
    }

    const onFlashAnimationEnd = () => {
        setIsFlashing(false);
    }

    const cameraCapture = (image) => {
        setTimeout(()=>{
            dispatch({type: 'setIsWebcamActive', payload:false});
            onImageCaptured(image)
        },500);
    }

    if (!mediaStream) {
        return null;
    }

    if(isMobileOnly){
        return ( <><Measure bounds onResize={handleResize}>
            {({ measureRef }) => (

                <Wrapper ref={measureRef}>
                    <Container className="camera-portrait" ref={measureRef} maxHeight={videoRef.current && videoRef.current.videoHeight}
                               maxWidth={videoRef.current && videoRef.current.videoWidth}>
                        <Video ref={videoRef} hidden={!state.isVideoPlaying}
                               onCanPlay={handleCanPlay}
                               autoPlay
                               playsInline
                               muted/>
                        <Canvas ref={canvasRef} width={container.width} height={container.height}/>
                        <Flash flash={isFlashing} onAnimationEnd={onFlashAnimationEnd}/>
                    </Container>
                </Wrapper>


            )}
        </Measure>
            {state.isVideoPlaying && (
                <Button className="btn-pic btn-camera" onClick={(e)=>handleCapture(e)}>
                    <FontAwesomeIcon icon={["fas", "camera"]}/>
                    {"Capture"}
                </Button>
            )}
        </>);

    }

    if(isTablet && isIOS){

        return ( <><Measure bounds onResize={handleResize}>
            {({ measureRef }) => (

                <Wrapper ref={measureRef}>
                    <Container className="camera-portrait" ref={measureRef} maxHeight={videoRef.current && videoRef.current.videoHeight}
                               maxWidth={videoRef.current && videoRef.current.videoWidth}>
                        <Video ref={videoRef} hidden={!state.isVideoPlaying}
                               onCanPlay={handleCanPlay}
                               autoPlay
                               playsInline
                               muted/>
                        <Canvas ref={canvasRef} width={container.width} height={container.height}/>
                        <Flash flash={isFlashing} onAnimationEnd={onFlashAnimationEnd}/>
                    </Container>
                </Wrapper>


            )}
        </Measure>
            {state.isVideoPlaying && (
                <Button className="btn-pic btn-camera" onClick={(e)=>handleCapture(e)}>
                    <FontAwesomeIcon icon={["fas", "camera"]}/>
                    {"Capture"}
                </Button>
            )}</>);


    }else{
        return ( <><Measure bounds onResize={handleResize}>
            {({ measureRef }) => (

                <Wrapper ref={measureRef}>
                    <Container className="camera-portrait" ref={measureRef} maxHeight={videoRef.current && videoRef.current.videoHeight}
                               maxWidth={videoRef.current && videoRef.current.videoWidth}>
                        <Video ref={videoRef} hidden={!state.isVideoPlaying}
                               width={container.width} height={container.height}
                               onCanPlay={handleCanPlay}
                               autoPlay
                               playsInline
                               muted/>
                        <Canvas ref={canvasRef} width={container.width} height={container.height}/>
                        <Flash flash={isFlashing} onAnimationEnd={onFlashAnimationEnd}/>
                    </Container>
                </Wrapper>


            )}
        </Measure>
            {state.isVideoPlaying && (
                <Button className="btn-pic btn-camera" onClick={(e)=>handleCapture(e)}>
                    <FontAwesomeIcon icon={["fas", "camera"]}/>
                    {"Capture"}
                </Button>
            )}
        </>);
    }

}

export default Camera;
