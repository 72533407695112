import React, {useEffect, useState, useRef, useCallback} from "react";
import {NavLink, useRouteMatch} from "react-router-dom";
import {UseLocalStorage} from "../services/UseLocalStorage";
import { useHistory } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import useGeolocation from "react-hook-geolocation";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faSignOutAlt,faCog,faWifi, faSuitcase, faArrowLeft,faServer,faClipboardList, faDownload, faList, faMoon, faSun, faCloudUploadAlt, faGlobe, faMapPin, faSync, faCodeBranch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DarkModeBtn from "../components/DarkModeBtn";
import LoadingCircle from "../components/LoadingCircle";
import {useDispatch, useSelector, useStore} from "react-redux";
import {push} from "connected-react-router";
import {
    selectDeliveryListUpdateTime, selectLoadingState,
    selectNewJobCount,
    selectOnlineState, selectQueryParams,
    selectQueueSelectedState, selectSyncingState,
    selectVehicleCheckedState
} from "../store";
import {Badge, Button, Container, Navbar} from "react-bootstrap";
import {useDepServices} from "../services/DepServicesContext";
import {useSessionService} from "../services/SessionService";
import {createFetchAppSettings, createLogout} from "../reducers/appStateReducer";
import {useTranslation} from "react-i18next";
import {backToSetting, isServiceAlive, notify, setApplayerOrientationLock} from "../utilitis/helper";
import {setUserQueues} from "../reducers/deliveryStateReducer";
import {LOCAL_TAG_REMEMBER_ME} from "../constains/types";
import UseAutoUpdate from "../components/UseAutoUpdate";
import {MSOGAPIClient} from "../utilitis/MSOGAPIClient";

library.add(fas, faSignOutAlt, faCog,faWifi,faSuitcase, faArrowLeft, faServer, faClipboardList, faDownload, faList,faMoon,faSun,faCloudUploadAlt, faGlobe, faMapPin, faSync,faCodeBranch );

const Navigation = (props) => {

    const newJobCount = useSelector(selectNewJobCount)
    const {deliveriesService} = useDepServices()
    const [completedJobCount, ongoingJobCount] = deliveriesService.getLocalJobCounts()

    const isOnline = useSelector(selectOnlineState);
    const isQueueSelected = useSelector(selectQueueSelectedState);
    const isVehicleChecked = useSelector(selectVehicleCheckedState);
    const isLoading = useSelector(selectLoadingState);
    const isSyncing = useSelector(selectSyncingState);
    const {isServiceWorkerUpdated} = UseAutoUpdate(isSyncing);
    const [rememberMe, setRememberMe] = UseLocalStorage(LOCAL_TAG_REMEMBER_ME, false);
    const query = new URLSearchParams(useSelector(selectQueryParams));
    const embeddedInAppLayer = query.get('isEmbeddedInAppLayer') === 'true';

    const sessionService = useSessionService();
    const dispatch = useDispatch();
    const store =useStore();

    const {t} = useTranslation();

    let history = useHistory();
    const url = 'navdata.json';

    const [data,setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const [networkBtn,setNetworkBtn] = useState(false);
    const [settingsBtn,setSettingsBtn] = useState(false);

    const focusedBtn = useRef();
    const geolocation = useGeolocation({ timeout: 10000});

    const [backToBase,setBackToBase] = UseLocalStorage("back_to_base",false);

    const navArray = [{"index":"1", "type": "btn", "text": "Network", "url": ".", "exact":false, "strict":true},
                        {"index":"2", "type": "link", "text": "Jobs", "url": "/jobs","exact":false,  "strict":true},
                        {"index":"3", "type": "link", "text": "Vehicle", "url": "/vehicle","exact":false,  "strict":true},
                        {"index":"4", "type": "btn", "text": "Sync", "url": ".","exact":false,  "strict":true},
                        {"index":"5", "type": "btn", "text": "Settings", "url": ".", "exact":false,  "strict":true}];
    const JobUpdateURL = "/api/v1/job/updatejobs";
    const driverLogUrl = "/api/v1/driver_logs/savedriverlogs";
    const [isSubmitting,setIsSubmitting] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer `
    }
    useEffect( () => {
        setData(navArray);
        setLoad(true);
        detectCurrentPage();
        detectPageLocation();
    },[]);

    const match = useRouteMatch({
        path: "/deliveries/:guid",
        exact: true,
        strict: true,
        sensitive: true
    });

    const networkHandler =()=>{
       return (networkBtn === false ? setNetworkBtn(true): setNetworkBtn(false)  );
    }

    const settingsHandler = ()=>{
        return (settingsBtn === false ? setSettingsBtn(true): setSettingsBtn(false)  );
    }

    const logoutHandler = async () =>{

        if (isSyncing || isLoading) {
            notify('error', 'logout.inprogress');
        }
        else if (completedJobCount !== 0 || ongoingJobCount !== 0) {
             notify('error', 'logout.unfinishedjob');
        }
        else {
            setIsSubmitting(true);
            dispatch(createLogout(sessionService, new MSOGAPIClient(store), result => {
                sessionService.clearSession(rememberMe);
            }));
        }
    }

    const detectCurrentPage = () => {
        let pathArray =  window.location.pathname.split('/');
        let currentPage = pathArray[1];

        if(currentPage === "deliveries"){
            return currentPage +" active";
        }
    }

    const detectPageLocation = ()=>{
        let pathArray =  window.location.pathname.split('/');
        let currentPage = pathArray[1];
        return currentPage;
    }

    const renderDeliveriesLink  = () =>{
        let currentPage = detectPageLocation();
        if (! isQueueSelected) {
            return <></>;
        }else if(currentPage === "deliveries" && match && match.isExact === true){
            return(<a className={"btn-jobs btn-def no-border no-bg "+ detectCurrentPage()} >
                <FontAwesomeIcon icon={["fas", "truck"]} />
            </a>);
        }else{
            return(<NavLink className={"btn-jobs btn-def no-border no-bg "+ detectCurrentPage()} exact={false} to="/vehicle" strict={true} >
                <FontAwesomeIcon icon={["fas", "truck"]} />
            </NavLink>);
        }
    }
    const renderJobLink = () =>{
        let currentPage = detectPageLocation();
        if(currentPage === "deliveries" && match && match.isExact === true) {
            return (<a className={"btn-jobs btn-def no-border no-bg"}>
                <FontAwesomeIcon icon={["fas", "suitcase"]}/>
            </a>);
        }else{
            return (<NavLink className={"btn-jobs btn-def no-border no-bg"} exact={false} to="/jobs" strict={true}>
                <FontAwesomeIcon icon={["fas", "suitcase"]}/>
            </NavLink>);
        }
    }

    const renderSync = () => {
        return(<>{isQueueSelected && isVehicleChecked ?
            <button className="no-bg no-border btn-def position-relative" disabled={(newJobCount === 0 && completedJobCount === 0) || isSyncing || isServiceWorkerUpdated} onClick={syncJobHandler}>
                <FontAwesomeIcon icon={["fas", "sync-alt"]} className={isSyncing?"fa-spin":""}/>
                {(newJobCount > 0 || completedJobCount > 0) && !isSyncing ? <Badge
                    className={"position-absolute top-100 left-0 translate-middle bg-danger"}>{newJobCount + completedJobCount}</Badge> : <></>}
            </button> : <></>
        }</>);
    }

    const renderNavContent = () =>{
        return(<>
            {isServiceAlive(isOnline)? <button  ref={focusedBtn} className={networkBtn? "btn-network btn-def no-border online no-bg active" :"network-btn btn-def online no-border no-bg"} onClick={networkHandler}>
                            <FontAwesomeIcon  icon={["fas", "wifi"]} />
                        </button>
                        :<button  ref={focusedBtn} className={networkBtn? "btn-network btn-def no-border no-bg active" :"network-btn btn-def no-border no-bg"} onClick={networkHandler}>
                        <FontAwesomeIcon  icon={["fas", "wifi"]} />
                        </button>
            }
            {renderJobLink()}
            {renderDeliveriesLink()}
            {renderSync()}
            <button ref={focusedBtn} className={settingsBtn? "btn-settings btn-def no-border no-bg active" :"btn-settings btn-def no-border no-bg"}  onClick={settingsHandler}>
                <FontAwesomeIcon icon={["fas", "cog"]} />
            </button>
        </>);
    }

    const isGeoLocationAvailable = () =>{

            return(geolocation.accuracy !== null && geolocation.accuracy !== undefined ? <span><FontAwesomeIcon icon={["fas", "map-pin"]} className="online" />
            <span className="online" >GPS connection</span></span> : <span><FontAwesomeIcon icon={["fas", "map-pin"]} /> <span>No GPS connection</span></span>);
    }


    const syncJobHandler = () => {

        if ( isOnline ) {
            deliveriesService.syncDeliveriesJob();
        } else {
            notify('error', 'syncjob.offline')
        }
    };

    const syncAppHandler = () =>{
        setIsSubmitting(true);

    }

    const renderBackToBase = () =>{
        if(backToBase == true){
            return (<button className="btn-base-back">Back To Base</button>)
        }
    }

    const onBackToSettingsClicked = () => {
        setApplayerOrientationLock(true)
        backToSetting()
    }

    // const renderSyncAppButton = () =>{
    //     if(geolocation.accuracy === undefined){
    //         return(
    //             <button disabled={true} className="btn-sync offline">
    //                 <FontAwesomeIcon icon={["fas", "sync"]} className="mr-3" /> <span>No GPS Location!</span>
    //             </button>)
    //     }else{
    //         return(<>
    //             {renderBackToBase()}
    //             <Offline>
    //                 <button disabled={true} className="btn-sync offline">
    //                     <FontAwesomeIcon icon={["fas", "sync"]} className="mr-3" /> <span>App is Offline!</span>
    //                 </button>
    //             </Offline>
    //             <Online>
    //                 <button type="button"   disabled={isSubmitting} className="btn-sync" onClick={syncAppHandler}>
    //                     {isSubmitting? "":<FontAwesomeIcon icon={["fas", "sync"]} className="mr-3" />}
    //                     <div>{isSubmitting? <span>Syncing...</span>:<span>Sync App</span>}</div>
    //                     <LoadingCircle isSubmitting={isSubmitting} />
    //                 </button>
    //             </Online>
    //         </>);
    //     }
    //
    //
    // }
    const renderQueueName = () =>{
        const selectedQueue = sessionService.getDeliveredQueue()
        return selectedQueue !== false ? <span>{selectedQueue.name}</span> : <span>{t("ui:settings.no_queue_selected_message")}</span>;
    }

    const queueBtnHandler =()=>{
        if(ongoingJobCount === 0) {
            window.localStorage.removeItem('queue_name');
            //setFavouriteID(null);
            settingsHandler();
            history.push("/jobs");
        }else{
            /*
            messageDispatch({
                type: ADD,
                payload: {content: {error: "Error Logout", message: "Cannot change queues due to unfinished or un-returned jobs!"}, type: "error"}
            });

            setTimeout(()=>{
                messageDispatch({type:REMOVE_ALL,payload:""});
            },2000);*/
        }
    }
    const renderQueueBtn=()=>{
        //if(favQueueInt === 0){
            return (<div className="queue-holder"><button className="btn-back-to-queue" disabled={isSyncing || isSubmitting} onClick={queueBtnHandler}>Back to Queue Page</button></div>);
        //}

    }

    return (
        <>
            <Navbar expand="lg">
                <Container>
                    {renderNavContent()}
                </Container>
            </Navbar>
        {/*<nav className="d-flex d-flex align-content-center justify-content-center justify-content-between">*/}
        {/*    {renderNavContent()}*/}
        {/*</nav>*/}
        <div className={settingsBtn||networkBtn? "overlay active" : "overlay"}>
            <div className={networkBtn? "network-holder active" :"network-holder" }>
            <div className="content">
                <div className="title-holder d-flex flex-row align-items-center justify-content-center bg-primary p-2">
                    <div className="d-flex flex-row  align-items-center justify-content-between">
                        <h3 className="text-white me-3">Hi! {sessionService.getUserName()}</h3>
                        <button className={networkBtn? "btn-network-back btn-def border-circle bg-grey-light active" :"network-btn btn-def no-border no-bg"} onClick={networkHandler} disabled={isSubmitting}>
                            <FontAwesomeIcon  icon={["fas", "arrow-left"]} />
                        </button>
                    </div>
                </div>
                <div className="content mt-3 d-flex flex-column align-items-center justify-content-center">
                    <div className="maxw-320 w-100">
                        <h4 className={'page-header'}>{t("ui:network_status.header")}</h4>
                        <ul>
                            <li><span><FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} className={isServiceAlive(isOnline) ? 'online' : ''}/> <span className={isServiceAlive(isOnline) ? 'online' : ''}>Cloud connection</span></span></li>
                            <li>
                                <Online><FontAwesomeIcon icon={["fas", "globe"]}  className="online" /> <span  className="online">Internet connection online</span></Online>
                                <Offline><FontAwesomeIcon icon={["fas", "globe"]} /> <span>Internet connection offline</span></Offline>
                            </li>
                            <li className="online">
                                {networkBtn?isGeoLocationAvailable():""}
                            </li>
                        </ul>
                        {/*{renderSyncAppButton()}*/}
                    </div>
                </div>
            </div>
        </div>
            <div className={settingsBtn? "settings-holder active" :"settings-holder" }>
            <div className="content">
                <div className="title-holder d-flex flex-row align-items-center justify-content-center bg-primary p-2">
                    <div className="d-flex flex-row align-items-center justify-content-between maxw-680">
                        <h3 className="text-white me-3">Hi! {sessionService.getUserName()}</h3>
                        <button className={settingsBtn? "btn-settings-back btn-def border-circle bg-grey-light active" :"settings-btn btn-def no-border no-bg"}  onClick={settingsHandler} disabled={isSubmitting}>
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                        </button>
                    </div>
                </div>
                <div className="content mt-3 d-flex flex-column align-items-center justify-content-center">
                    <div className="maxw-320 w-100">
                        <h4 className={'page-header'}>{t("ui:settings.header")}</h4>
                        <ul>
                            {/*<li><span><FontAwesomeIcon icon={["fas", "server"]} /> <span>Server Name</span></span></li>*/}
                            <li className="queue-row">
                                <div className="queue-top"><FontAwesomeIcon icon={["fas", "clipboard-list"]}/>{renderQueueName()}</div>
                                {renderQueueBtn()}
                            </li>
                            <li className="version-row"><div className="version-content"><FontAwesomeIcon icon={["fas", "code-branch"]}/> <span>Current version: {process.env.REACT_APP_VERSION}<small>({process.env.REACT_APP_BUILD})</small></span></div>
                            </li>
                            <li><span><FontAwesomeIcon icon={["fas", "list"]}/> <span>Logs</span></span></li>
                            <li className="d-flex flex-row"><span>Dark Mode</span>
                                <DarkModeBtn/>
                            </li>
                            {embeddedInAppLayer ? <li className="d-flex flex-row"><span>Back To Settings</span>
                                <Button onClick={onBackToSettingsClicked}>Back To Settings</Button>
                            </li> : <></>}
                        </ul>
                    </div>
                </div>
            <button className="btn-logout btn-def-rnd btn-blue-base p-2 d-flex flex-row justify-content-center align-items-center m-auto w-100" onClick={logoutHandler} disabled={isSubmitting}>
                <FontAwesomeIcon icon={["fas", "sign-out-alt"]} />
                <span>{isSubmitting? "Loading ...": "Logout"}</span>
                <LoadingCircle isSubmitting={isSubmitting} />
            </button>
            </div>
        </div>
        </div>
        </>);
};

export default Navigation;
