import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faChevronLeft,faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Pagination} from "react-bootstrap";
library.add(fas,faChevronLeft,faChevronRight);

const Paginator = ({postPerItem,totalItems,paginate,currentItem})=>{
    const pageNumbers=[];

    const nextPage = (e) =>{
        if(currentItem < pageNumbers.length - 1){
            paginate(currentItem + 1);
        }
        e.preventDefault();
    }

    const prevPage = (e) =>{
        if(currentItem > 0){
            paginate(currentItem - 1);
        }
        e.preventDefault();
    }

    for (let i = 0; i < Math.ceil(totalItems / postPerItem); i ++){
        pageNumbers.push(i);
    }

    return (
        <div className={"col-12"}>
            <nav>
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <a className="page-link" onClick={(e) => prevPage(e)} aria-label="Previous">
                        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                    </a>
                </li>
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentItem === number ? "active" : ""}`}>
                        <a onClick={(e) => paginate(number)} className="page-link">
                            {number + 1}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" onClick={(e) => nextPage(e)} aria-label="Next">
                        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                    </a>
                </li>
            </ul>
            </nav>
        </div>
        // <nav className="pagination">
        //     <button className="btn-pagination-prev" onClick={(e) => prevPage(e)}>
        //         <FontAwesomeIcon icon={["fas", "chevron-left"]} />
        //     </button>
        //     <ul>
        //         {pageNumbers.map(number => (
        //             <li key={number} className={`page-item${currentItem === number ? " active" : ""}`}>
        //                 <a onClick={(e) => paginate(number)} className="page-link">
        //                     {number + 1}
        //                 </a>
        //             </li>
        //
        //         ))}
        //     </ul>
        //     <button className="btn-pagination-next" onClick={(e) => nextPage(e)}>
        //         <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        //     </button>
        // </nav>
    );
}

export default Paginator;
