import React from "react";

const StaticMap = ({staticMap,lat,long,image, address})=>{
    return(
        <div className={staticMap? "delivery-map active" : "delivery-map"} >
            <a href={'https://www.google.com/maps/search/?api=1&query='+lat+','+long} target="_blank" rel="noopener noreferrer">
                <img className="img-fluid" src={'data:image/png;base64, '+image} alt={address} title={address}/>
            </a>
        </div>
    )
}

export default StaticMap;
