import React, {useCallback} from 'react';
import {useMSOGForm} from "../services/MSOGFormContext";

const UseFormVehicle = () => {

    const {dispatch} = useMSOGForm();

    const handleChange = useCallback((e) => {
        const target = (e.target.className === "yesnoswitch-switch" || e.target.className === "yesnoswitch-inner") ? e.currentTarget.firstChild : e.target,
            field_value = target.type === 'checkbox' ? target.checked : (target.value ?? e.detail),
            guid = target.getAttribute("data-guid");
        dispatch({type: 'update_item', payload: {id: guid, value: field_value}});
        if (e.persist)
            e.persist();
    }, []);

    return {handleChange}
};

export default UseFormVehicle;
