import React from "react";
import LoadingCircle from "../../../components/LoadingCircle";

const SubmitButton = ({isSubmit,id, disabled})=>{
        return ( <button type="submit" data-id={id}  disabled={isSubmit || disabled} className="btn-submit">
            <div>{isSubmit? "Loading...": "Submit" }</div>
            <LoadingCircle isSubmitting={isSubmit} />
        </button>)
}

export default SubmitButton;
