import React from "react";

const CaptureOptions = (CAPTURE_OPTIONS)=>{

    CAPTURE_OPTIONS = {
        audio: false,
        video: { facingMode: "environment" }
    };

    return CAPTURE_OPTIONS;
}
export default CaptureOptions;