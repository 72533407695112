import React, { createContext, useReducer, useContext } from "react";
import { createPortal } from "react-dom";
import Message from "../components/Message";

export const MessageContext = createContext();

const initialState = [];

export const ADD = "ADD";
export const REMOVE = "REMOVE";
export const REMOVE_ALL = "REMOVE_ALL";

export const messageReducer = (state, action) => {
    switch (action.type) {
        case ADD:
            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload.content,
                    type: action.payload.type
                }
            ];
        case REMOVE:
            return state.filter(t => t.id !== action.payload.id);
        case REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

export const MessageProvider = (props) => {
    const [message, messageDispatch] = useReducer(messageReducer, initialState);
    const messageData = { message, messageDispatch };
    return (
        <MessageContext.Provider value={messageData}>
            {props.children}

            {createPortal(<Message message={message} />, document.body)}
        </MessageContext.Provider>
    );
};

export const useMessageContext = () => {
    return useContext(MessageContext);
};
