import React, {memo, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CameraBtn from "./CameraBtn";
import SpinnerDatabase from "../SpinnerDatabase";
import {useTranslation} from "react-i18next";
import {Button, Col} from "react-bootstrap";
import {element} from "prop-types";
import {useSelector} from "react-redux";
import {useDeliveryForm} from "../../../services/DeliveryFormContext";

const LineItemInput = memo(({index, id, adjustment, maxQuantity, availableActions, allActions, handler}) =>{

    const [quantity, setQuantity] = useState(adjustment.quantity);
    const [action, setAction] = useState(adjustment.action !== "" ? adjustment.action : ( availableActions.length > 0 ? availableActions[0].action : ""));
    const [enableEdit, setEnableEdit] = useState(adjustment.action === "");
    const {t, i18n} = useTranslation();
    const actionObj = action && action !== "" ? allActions.find( element => element.action === action) : null;
    const {formValues} = useDeliveryForm();


    const btnIncrement = (e) =>{

        if(quantity < maxQuantity + adjustment.quantity && actionObj.operator !== "=" && formValues.job_status === 2){
            setQuantity(quantity + 1 );
        }

        e.preventDefault();
    }

    const btnDecrement = e => {

        if(quantity > 0 && actionObj.operator !== "=" && formValues.job_status === 2){
            setQuantity(quantity - 1);
        }

        e.preventDefault();
    }

    const lineActionValue = e =>{
        setAction(e.target.value);
        const action = availableActions.find( element => element.action === e.target.value);
        if (action && action.operator === "=") {
            setQuantity(maxQuantity);
        } else {
            setQuantity(0);
        }
        e.preventDefault();
    }

    const handleChange = (e) => {

        let stringToNum = parseInt(e.target.value);
        setQuantity( sanitiseQuantity(stringToNum) );
        e.preventDefault();
    };

    const sanitiseQuantity = (value) => {
        return value > maxQuantity + adjustment.quantity ? maxQuantity + adjustment.quantity : (value < 0 ?  0 : value)
    }

    const renderAdjEditor = () => {
        return(
            <div key={index} className="add-lineitem input-holder justify-content-end">
                <Col xs={12} md={6} className={"text-center text-md-start"}>
                {(adjustment.action === "") ? <select className="select-css" id={"select-" + id + "-" + index} data-id={id + "-" + index}
                    value={action} onChange={lineActionValue} style={{minWidth:"215px"}} disabled={formValues.job_status !== 2}>
                    { availableActions.map((item,index) => <option key={index} value={item.action}>{item.action}</option>)}
                </select> : <label className="label-line-item"> {action} </label>}
                </Col>
                <Col xs={12} md={5}>
                <div className="lineitem-btn-inputs">
                    <a className={`btn-lineminus ${actionObj && actionObj.operator === "=" || formValues.job_status !== 2 ? 'disabled' : ''}`} onClick={btnDecrement} disabled={actionObj && actionObj.operator === "=" || formValues.job_status !== 2}>
                        <FontAwesomeIcon icon={["fas", "minus"]}/>
                    </a>
                    <input type="number" className="input-line-item" max={maxQuantity} value={quantity} onChange={handleChange} disabled={actionObj && actionObj.operator === "=" || formValues.job_status !== 2}/>
                    <a className={`btn-lineadd ${actionObj && actionObj.operator === "=" || formValues.job_status !== 2? 'disabled' : ''}`} data-id={id} onClick={btnIncrement} disabled={actionObj && actionObj.operator === "=" || formValues.job_status !== 2}>
                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                    </a>
                    <button type="button" className="btn-linecheck" data-guid={id} onClick={(e) => {
                        e.detail = {action: action, quantity: sanitiseQuantity(quantity)};
                        handler(e);

                        // only clear the input if it is new created element
                        if ( adjustment.action === "" ) {
                            const nextAction = availableActions.find(element => element.action !== action)
                            setQuantity(0);
                            setAction(nextAction ? nextAction.action : '');
                        } else {
                            setEnableEdit(false);
                            setQuantity( sanitiseQuantity(quantity) );
                        }
                    }} disabled={action===""|| quantity===0 || formValues.job_status !== 2}><FontAwesomeIcon icon={["fas", "check"]}/>
                    </button>
                </div>
                </Col>
            </div>
        );
    }

    const renderAdjConfirmed = () => {
        return (
            <div className={"add-lineitem"}>
                <p className="modified-result col-6">
                    <FontAwesomeIcon icon={["fas", "exclamation-circle"]}/><span>{action} items {quantity}</span>
                </p>
                <div className="lineitem-btn-inputs col-5 text-end">
                    <Button variant={"danger"} data-guid={id} onClick={e=> {
                        e.detail = {action: action, quantity: 0};
                        handler(e);
                    }} className={"me-3"} disabled={formValues.job_status !== 2}>
                        <FontAwesomeIcon icon={["fas", "trash-alt"]}/>
                    </Button>
                    <Button variant={"primary"} onClick={ e =>setEnableEdit(!enableEdit)} disabled={formValues.job_status !== 2}>
                        <FontAwesomeIcon icon={["fas", "edit"]}/>
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <>
        {(enableEdit) ? renderAdjEditor() : renderAdjConfirmed()}
        </>
    );
});

export default LineItemInput;
