import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faUndoAlt} from '@fortawesome/free-solid-svg-icons';
import {Online } from "react-detect-offline";
library.add(fas,faUndoAlt);

const ReturnJobBtn = ({handler}) =>{
 if(Online){
     return (<button className={"btn-returnjob"} onClick={handler}>
         <FontAwesomeIcon icon={["fas", "undo-alt"]}/>
         <span>Return Job</span>
     </button>);
 }else{
     return (<button className={"btn-returnjob"} disabled={true}>
                <FontAwesomeIcon icon={["fas", "undo-alt"]}/>
            <span>Return Job</span>
     </button>);
 }
}
export default ReturnJobBtn;