import styled, { keyframes, css } from "styled-components";

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display:flex;
  width:100%;
  align-items:center;
  justify-content: center;
`;

export const Header = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom:0;
  z-index:4;
`;


export const Container = styled.div`
  position: relative;
`;

export const Canvas = styled.canvas`
  position: absolute;
  display:flex;
  flex-direction:column;
  right: 0;
  top: 0;
  left: 0;
  bottom:0;
  z-index:2;
`;

export const Video = styled.video`
  position: relative;
  width:100%;
  right: 0;
  top: 0;
  left: 0;
  bottom:0;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
  z-index:3;
  ${({ flash }) => {
    if (flash) {
        return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
}}
`;

export const Button = styled.button`
  width: auto;
`;


export const Root = styled.div``;


export const Footer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  button {
    margin: 0 10px;
  }
`;
