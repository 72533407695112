import React, {useState, useEffect} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import Login from "./Login";
import Jobs from "./Jobs";
import Vehicle from "./Vehicle/Vehicle";
import PageNotFound from "./PageNotFound";
import Error from "./Error";
import Deliveries from "./Deliveries/Deliveries";
import {selectLoginState, selectPathName, selectSessionUpdateTime} from "../store";
import {useSessionService} from "../services/SessionService";
import Confirm from "./Confirm/Confirm";
import {push} from "connected-react-router";
import {useDepServices} from "../services/DepServicesContext";
import {setApplayerOrientationLock} from "../utilitis/helper";


export const Main = () =>{

    const dispatch = useDispatch();
    const isLogin = useSelector(selectLoginState);
    const sessionChangedAt = useSelector(selectSessionUpdateTime);
    const pathState = useSelector(selectPathName);
    const {deliveriesService} = useDepServices();
    const sessionService = useSessionService();

    let history = useHistory();

    const[data,setData] = useState([]);

    const pagesArray = [ {type: "page", text: "Login", url: "/", exact: true, strict:true , login: false, component: Login},
        {type: "page", text: "Login", url: "/login", exact: true, strict: true, login: false, component: Login },
        {type: "page", text: "Jobs", url: "/jobs", exact: false, strict: true, login: false, component: Jobs },
        {type: "page", text: "Confirm", url: "/confirm", exact: false, strict: true, login: false, component: Confirm },
        {type: "page", text: "Vehicle", url: "/vehicle", exact: false, strict: true, login: false, component: Vehicle},
        {type: "page", text: "Deliveries", url: "/deliveries", exact: false, strict: true, login: false, component: Deliveries},
        {type: "page", text: "Error", url: "/error", exact: false, strict: true, login: false, component: Error},
        {type: "redirect", text: "PageNotFound", url: "", exact: true, strict: false, login: false, component: PageNotFound}];


    const getState = () => {
        if (document.visibilityState === 'hidden') {
            return 'hidden';
        }
        if (document.hasFocus()) {
            return 'active';
        }
        return 'passive';
    };

    let displayState = getState();


    const onDisplayStateChange = () => {
        const nextState = getState();
        const prevState = displayState;

        if (nextState !== prevState) {
            console.log(`State changed: ${prevState} >>> ${nextState}`);
            displayState = nextState;

            //standalone will restrict to only running for an installed PWA on mobile
            if (nextState === 'active' /* && standalone */) {
                //The app/browser tab has just been made active and is visible to the user
                //do whatever you want in here to update dynamic content, call api etc
            }
        }
    };

    useEffect( () => {
        const fetchPages = () =>{
            setData(pagesArray);
        }
        fetchPages();
        setApplayerOrientationLock(false);

        window.addEventListener('visibilitychange', onDisplayStateChange, {capture: true});

        return () => {
            window.removeEventListener('visibilitychange', onDisplayStateChange);
            setApplayerOrientationLock(true);
        }
    },[]);

    useEffect( () => {

        if(! sessionService.isLogin()) {
            deliveriesService.clearCache();
            dispatch(push('/'));
        }
    },[sessionChangedAt]);


    const pushToPath = ()=>{
        const pathname = (pathState === "/") ? (sessionService.isLogin() ? "/deliveries":"/login") : null;
        return (pathname ? <Redirect to={pathname} /> : "");
    }

    const RouteTags = data.map((routelink, index) => {
        return <Route exact={routelink.exact} key={index} path={routelink.url} component={(!sessionService.isLogin() && routelink.login) ? PageNotFound : routelink.component} strict={routelink.strict} />
    });

    return(<main className="d-flex fa-1x flex-grow-1 align-content-center justify-content-center">
                    <Switch basename={'/'}>
                        {RouteTags}
                        {pushToPath()}
                    </Switch>
            </main>);
};
export default Main;
