import React, {createContext, useContext} from "react";


const PhotoInputStateContext = createContext(null);

export const PhotoInputStateContextProvider = ({children, state, dispatch}) => {

    const value = {state, dispatch}
    return (
        <PhotoInputStateContext.Provider value={value}>
            {children}
        </PhotoInputStateContext.Provider>
    );
};

export const usePhotoInputState = () => useContext(PhotoInputStateContext);
