import {SET_JOB_STATUS} from "../constains/deliveryFormActions";

const formReducer = (state, action) => {
    switch (action.type) {
        case 'update_selected_jobguids' :{
            return {...state, job_guids: action.payload}
        }
        case SET_JOB_STATUS :{
            return {...state, job_status: action.payload}
        }
        case 'update_extra': {
            const index = state.job_extras.findIndex( element => element.guid === action.payload.guid),
                target = state.job_extras[index];
            return (target.field_value !== action.payload.value) ? {...state, job_extras: [...state.job_extras.slice(0,index), {...target, field_value: typeof action.payload.value === "object" ? JSON.parse(JSON.stringify(action.payload.value)) : action.payload.value, refno: (target.refno) ? target.refno + 1 : 1}, ...state.job_extras.slice(index + 1)]} : state;
        }
        case 'update_item_photos': {
            const index = state.line_items.findIndex( element => element.guid === action.payload.guid),
                target = state.line_items[index];
            return (target.photos !== action.payload.value) ? {...state, line_items:[...state.line_items.slice(0, index), {...target, photos: action.payload.value ? [...action.payload.value] : [], refno: (target.refno) ? target.refno + 1 : 1}, ...state.line_items.slice(index + 1)]} : state;
        }
        case 'update_item_adj': {
            const index = state.line_items.findIndex( element => element.guid === action.payload.guid),
                target = index !== -1 ? state.line_items[index] : null
            if (target) {
                let adjustmentIdx = target.adjustment.findIndex(element => element.action === action.payload.value.action),
                    adjustment = JSON.parse(JSON.stringify(target.adjustment))
                if (~adjustmentIdx) {
                    if (action.payload.value.quantity === 0) {
                        adjustment.splice(adjustmentIdx,1);
                        adjustmentIdx = -1;
                    } else {
                        adjustment[adjustmentIdx].quantity = action.payload.value.quantity;
                    }
                } else if (action.payload.value.quantity !== 0) {
                    adjustment.push(action.payload.value);
                }
                return (adjustmentIdx === -1 || target.adjustment[adjustmentIdx].quantity !== action.payload.value.quantity) ? {...state, line_items:[...state.line_items.slice(0, index), {...target, adjustment: adjustment , refno: (target.refno) ? target.refno + 1 : 1}, ...state.line_items.slice(index + 1)]} : state;
            }
            return state;
        }
        case 'initial_value': {
            return action.payload;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

export default formReducer;
