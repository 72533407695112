import React, {useState,useEffect} from "react";
import {useStateIfMounted} from "use-state-if-mounted";
import {fetchLocalStorage, setLocalStorage} from "../utilitis/helper";

export const UseLocalStorage = (key, initialValue) =>{
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useStateIfMounted(() => {
        return fetchLocalStorage(key, initialValue);
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    useEffect(() => {
        var result;
        if ((result = setLocalStorage(key, storedValue)) !== true) {
            console.log(result);
        }
    }, [key, storedValue]);

    return [storedValue, setStoredValue];
}
