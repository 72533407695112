import React, {useEffect, useContext, createContext} from "react";
import {useStateIfMounted} from "use-state-if-mounted";
import {MSOGAPIClient, MSOGAPIError} from "./MSOGAPIClient";
import {useDispatch, useStore} from "react-redux";
import {setOnlineState} from "../reducers/runtimeStateReducer";
import {NETWORK_STATUS_INDEX, CLOUD_CONN_STATUS_INDEX} from "./helper";
import UseLogoutWhenUnAuth from "../components/UseLogoutWhenUnAuth";

const PING_RESOURCE = "/ping";
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;


const timeout = (time, promise) => {
    return new Promise(function(resolve, reject) {
        setTimeout(() => {
            reject(new Error("Request timed out."));
        }, time);
        promise.then(resolve, reject);
    });
};

const checkOnlineStatus = async (client) => {
    //const controller = new AbortController();
    //const { signal } = controller;

    // If the browser has no network connection return offline
    if (!navigator.onLine) return navigator.onLine;

    //
    try {
        let result = await client.ping();
        return (!(result instanceof MSOGAPIError)) << 1 | navigator.onLine;
    } catch (error) {
        // Error Log
        console.error(error);
    }
    return NETWORK_STATUS_INDEX;
};

const OnlineStatusContext = createContext(NETWORK_STATUS_INDEX | CLOUD_CONN_STATUS_INDEX);

export const OnlineStatusProvider = ({ children, initClient}) => {
    const [onlineStatus, setOnlineStatus] = useStateIfMounted(NETWORK_STATUS_INDEX | CLOUD_CONN_STATUS_INDEX);
    const {handleUnAuth} = UseLogoutWhenUnAuth();
    const client = initClient;
    client.setUnauthorisedHandler(handleUnAuth);
    const dispatch = useDispatch();

    const checkStatus = async () => {
        const online = await checkOnlineStatus(client);
        setOnlineStatus(online);
        dispatch(setOnlineState(online));
    };

    useEffect(() => {
        window.addEventListener("offline", () => {
            setOnlineStatus(false);
            dispatch(setOnlineState(false));
        });

        // Add polling incase of slow connection
        const id = setInterval(() => {
            checkStatus();
        }, onlinePollingInterval);

        return () => {
            window.removeEventListener("offline", () => {
                setOnlineStatus(false);
                dispatch(setOnlineState(false));
            });

            clearInterval(id);
        };
    }, []);

    return (
        <OnlineStatusContext.Provider value={onlineStatus}>
            {children}
        </OnlineStatusContext.Provider>
    );
};

export const useOnlineStatus = () => {
    const store = useContext(OnlineStatusContext);
    return store;
};
