import React, {useContext, createContext, useReducer} from 'react';
import {element} from "prop-types";
import formReducer from "../reducers/deliveryFormReducer";

const DeliveryFormContext = createContext(null);

export const DeliveryFormContextProvider = ({children, jobguids, status, jobextras, lineitems, lineitemactions}) => {

    const [formValues, dispatch] = useReducer(formReducer, {job_guids:jobguids, job_status: status, job_extras: jobextras, line_items: lineitems, line_item_actions: lineitemactions});
    const value = {formValues, dispatch}

    return (
        <DeliveryFormContext.Provider value={value}>
            {children}
        </DeliveryFormContext.Provider>
    );
};

export const useDeliveryForm = () => useContext(DeliveryFormContext);
