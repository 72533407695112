import React, {useRef, memo, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import SignaturePad from "react-signature-canvas";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {isMobileOnly,isIOS,isBrowser} from "react-device-detect";
import UseWindowDimensions from "../../components/DetectWindowSize/WindowDimensions";
import UseScreenOrientation from "../../components/UseScreenOrientation";
import {useDeliveryForm} from "../../services/DeliveryFormContext";
import {useTranslation} from "react-i18next";
import UseScrollToElement from "../../components/UseScrollToElement";
import {isEmpty, notify} from "../../utilitis/helper";
import moment from "moment";

library.add(fas);

const SignatureComponent = memo(forwardRef(({fieldid, fieldname, value, signdateGuid, signdateFieldname, signdateValue, required, editable = true, register = () => {} , unregister = () => {}, handler = () => {}, setValue = () => {}}, ref) => {
    const {t, i18n} = useTranslation();
    const moment = require('moment');
    const [signatureImg, setSignatureImg] = useState(value ?? '');
    const [canvasEditable, setCanvasEditable] = useState(editable);
    const orientation = "landscape-primary";
    const {setEnableScroll} = UseScrollToElement("signature-comp", `btn-save-sig-${fieldid}`, isMobileOnly, ['landscape-primary', 'landscape-secondary']);
    //const { height, width } = UseWindowDimensions();

    const signCanvas = useRef({});
    const signInputReg = register(fieldid, {required: required == 1, onChange: (e) => {
        console.log(e);
        }});
    const [errors, setErrors] = useState({});

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions] = useState(getWindowDimensions());

    useEffect( () => {

        return function cleanup() {
            unregister(fieldid);
        }
    }, [])


    const clear = e => {
        signCanvas.current.clear();
        setValue(fieldid, "");
        setTmpPreview("");
        document.getElementById("btn-reset-"+fieldid).disabled = true;
        e.preventDefault();
    };


    const onSignEnd = e => {
        setTmpPreview(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        document.getElementById("btn-reset-"+fieldid).disabled = false;
        document.getElementById("btn-reset-"+fieldid).onclick = clear;
    }

    const setTmpPreview = src => {

        const tmpPreviewContainer = document.getElementById("tmpPreview");

        if (tmpPreviewContainer) {
            const tmpPreview = tmpPreviewContainer.firstElementChild
            tmpPreview.src = src;

            if (src !== "") {
                tmpPreviewContainer.classList.remove('d-none');
                document.getElementById("orientation-message").classList.add('d-none');
            } else {
                tmpPreviewContainer.classList.add('d-none');
                document.getElementById("orientation-message").classList.remove('d-none');
            }
        }
    }


    useImperativeHandle(ref, () => ({
        setErrors(errors) {
            setErrors(errors);
        },
        save(e) {
            save(e);
        },
        signAgainHandler(e) {
            signAgainHandler(e);
        },
        setCanvasEditable(value) {
            setCanvasEditable(value);
        }

    }));

    const save = e => {

        const saveSignDate = signdateGuid => {
            if (signdateGuid) {
                const signDate = moment().unix();
                propagateValueChange(signdateGuid, signDate);
                setValue(signdateGuid, signDate);
            }
        };

        if (signCanvas.current && ! signCanvas.current.isEmpty()) {
            const signImg = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");

            //propagate change only to stop redraw frequently
            if (signatureImg !== signImg) {

                setSignatureImg(signImg);
                setValue(fieldid, signImg);
                setEnableScroll(false);
                propagateValueChange(fieldid, signImg);
                saveSignDate(signdateGuid);
            }
        } else if (signatureImg === "" && required) {
            notify('error', 'global.sign_empty_value' );
        } else {
            //save delivery time even if no signature
            saveSignDate(signdateGuid);
        }
        //setClearToSubmit(true);
        e.preventDefault();
    }

    const signAgainHandler = (e) =>{

        setEnableScroll(true);
        setSignatureImg("");
        setValue(fieldid, "");
        setCanvasEditable(true);

        propagateValueChange(fieldid, "");
        if (signdateGuid) {
            propagateValueChange(signdateGuid, "");
            setValue(signdateGuid, "");
        }

        e.preventDefault();
    }

    const propagateValueChange = (guid, value) => {
        var input = document.createElement("input");
        input.type = "text";
        input.value = value;
        input.setAttribute("data-guid", guid);
        handler({target:input})
    }

    const RenderSignaturePad = () =>{
        let signPadWidth = 610,
            signPadHeight = 343;
        if (isMobileOnly) {
            signPadHeight = Math.min(windowDimensions.height, windowDimensions.width) - 100;
            signPadWidth = signPadHeight / 9 * 16;
        }

        return (
            <>
                <SignaturePad ref={signCanvas} data-guid={fieldid} clearOnResize={false} canvasProps={{width: signPadWidth, height: signPadHeight, className: `signatureCanvas ${errors && errors[fieldid] ? "is-invalid" : ""} ${(canvasEditable) ? '' : 'd-none'}`}} onEnd={onSignEnd} />
                <div className="btn-holders active"><button className="btn-clear" id={"btn-reset-"+fieldid} disabled>{t("ui:signature_component.btn_clear")}</button></div>
                <div className={`signatureCanvas disabled ${(canvasEditable) ? 'd-none' : ''}`} style={{width: signPadWidth, height: signPadHeight}}></div>
                <div className="invalid-feedback text-center">{t("ui:signature_component.signpad_message")}</div>
            </>
        );
    };

    const RenderImagePreview =()=>{
        return(<><h3 className="image-preview mt-2">{t("ui:signature_component.header_preview")}</h3><div className="sig-preview">
            <img className="img-fluid" src={signatureImg} alt={fieldname}/>
        </div></>)
    }

    const handleSignInpue = e => {
        //signInputReg.onChange(e);
        setValue(fieldid, e.target.value);
        handler(e);
        e.preventDefault();
    }

    const RenderSignturePanel = () => {
        return(
            <div className={`sig-pad-holder text-center ${!orientation || orientation !== "portrait-primary" || signatureImg ? 'active' : ''}`}>
                {!signatureImg ? <RenderSignaturePad/> : <RenderImagePreview />}
            </div>
        );
    }

    const RenderMessage = () => {
        return (
            <>
            <div className={`sig-btn-holder ${!signatureImg ? '' : 'hidden'}`}>
                <div className={'sig-preview d-none'} id={"tmpPreview"}>
                    <img className="img-fluid" src=""/>
                </div>
                <h3 id={"orientation-message"} className={`orientation-message p-3 ${errors && errors[fieldid] ? "is-invalid" : ""}`}>{t("ui:signature_component.orientation_message")}</h3>
                {/*<div className="invalid-feedback text-center">{t("ui:signature_component.signpad_message")}</div>*/}
            </div>
            </>
        );
    }

    return(
        <>
            { isMobileOnly && !signatureImg ? <RenderMessage />: null}
            <RenderSignturePanel />
        </>
    );
}), signPropsAreEqual);

function signPropsAreEqual(prevSign, nextSign) {
    return prevSign.fieldid === nextSign.fieldid && prevSign.errors === nextSign.errors && prevSign.required === nextSign.required;
}


export default SignatureComponent;
