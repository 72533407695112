import React,{useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {selectDisplayMode, selectDsiplayMode} from "../store";
import {DISPLAY_MODE_DARK, DISPLAY_MODE_LIGHT} from "../constains/types";
import {setDisplayMode} from "../reducers/appStateReducer";

const DarkModeBtn = () => {

    const dispatch = useDispatch();
    const darkMode = useSelector(selectDisplayMode);


    return(
    <div className={"yesnoswitch"}  onClick={(e) => dispatch(setDisplayMode(e.currentTarget.firstChild.checked ? DISPLAY_MODE_DARK: DISPLAY_MODE_LIGHT))}>
        <input type="checkbox"  className="yesnoswitch-checkbox" id="darkmode" defaultChecked={darkMode === DISPLAY_MODE_DARK}/>
        <label className="yesnoswitch-label" htmlFor="darkmode">
            <span className="yesnoswitch-inner"></span>
            <span className="yesnoswitch-switch"></span>
        </label>
    </div>);




    //     <div className={"darkmodeswitch"}>
    //     <input type="checkbox" name="darkmode" className="darkmodeswitch-checkbox" checked={darkMode === DISPLAY_MODE_DARK ? true : false} id="darkmode" onChange={(e) => dispatch(selectDsiplayMode(e.target.checked ? DISPLAY_MODE_DARK: DISPLAY_MODE_LIGHT))}/>
    //     <label className={"darkmodeswitch-label"} htmlFor="darkmode">
    //                                     <span className={"darkmodeswitch-inner"}>
    //                                         <div className="before">
    //                                             <FontAwesomeIcon icon={["fas", "moon"]} />
    //                                         </div>
    //                                         <div className="after">
    //                                              <FontAwesomeIcon icon={["fas", "sun"]} />
    //                                         </div>
    //                                     </span>
    //         <span className={"darkmodeswitch-switch"}></span>
    //     </label>
    // </div>)
}

export default DarkModeBtn;