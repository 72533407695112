import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faChevronRight, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {isMobileOnly} from "react-device-detect";

library.add(fas,faChevronRight, faChevronUp);

const PDFBtn = ({showPDFForm, handler}) =>{
    if(!isMobileOnly){
        return(<button className={"btn-pdf-sign"}><span>Sign PDF</span></button>);
    }else{
        return(<button className={showPDFForm? "btn-pdf-sign mobile active" :"btn-pdf-sign mobile"} onClick={handler}>
            <span>Details</span>
            {showPDFForm? <FontAwesomeIcon icon={["fas", "chevron-down"]}/>: <FontAwesomeIcon icon={["fas", "chevron-right"]}/>}
        </button>);
    }

}
export default PDFBtn;