import React, {useCallback} from 'react';
import {useDeliveryForm} from "../services/DeliveryFormContext";


const UseFormLineItems = () => {

    const {dispatch} = useDeliveryForm();

    const handleChange = useCallback((event) => {
        const target = event.currentTarget;
        const value = (target.value) ? target.value : event.detail;
        const guid = target.getAttribute("data-guid");

        dispatch({type: (target.type === 'button') ? 'update_item_adj' : 'update_item_photos', payload: {guid: guid, value: value}});
        if (event.persist)
            event.persist();
        }, []);

    return {handleChange};
};

export default UseFormLineItems;
