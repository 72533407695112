import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Document, Page} from "react-pdf";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import StaticMap from "../../views/Deliveries/StaticMap";
import StaticMapBtn from "../../views/Deliveries/buttons/StaticMapBtn";
import {Button, Col, Modal} from "react-bootstrap";

const StaticMapViewer = ({job}) => {

    const {t, i18n} = useTranslation();
    const [showStaticMap,setShowStaticMap] = useState(false);

    return(
        <>
            <Button variant={"primary"} size={"lg"} onClick={() => setShowStaticMap(true)}>
                <FontAwesomeIcon  icon={["fas", "map"]} />
                <span className={"btn-label"}>Map</span>
            </Button>
            <Modal show={showStaticMap} onHide={ () => setShowStaticMap(false)} centered>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className="text-center" >
                        <StaticMap staticMap={showStaticMap} lat={job["lat"]} long={job["long"]}
                                   image={job["static_map"]}
                                   address={job["full_address"]}/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default StaticMapViewer;
