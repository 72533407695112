import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faMapMarkedAlt, fas} from '@fortawesome/free-solid-svg-icons';
import {Button, Col, Modal} from "react-bootstrap";

library.add(fas,faMapMarkedAlt);

const StaticMapBtn = ({staticMap,handler}) =>{

    const [showStaticMap, setShowStaticMap] = useState(false);

    return (
        <>
            <Col xs={4}>
                <Button variant={"primary"} onClick={() => setShowStaticMap(true)}>
                    <FontAwesomeIcon  icon={["fas", "map"]} />
                    <span className={"ms-2"}>Map</span>
                </Button>
            </Col>
            <Modal show={showStaticMap} onHide={ () => setShowStaticMap(false)} centered>
                <Modal.Header closeButton/>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>)
}

export default StaticMapBtn;
